import "./App.css";

import Home from "./components/Home";
import NavBar from "./Layouts/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "aos/dist/aos.css";
import NavigateYourNext from "./components/NavigateYourNext";
import About from "./components/About";
import Investors from "./components/Investors";
import Vision from "./components/Vision";
import React, { useEffect } from "react";
import AOS from "aos";

import Products from "./components/Products";
import Contact from "./components/Contact";
import Chat from "./components/Chat";
import Gps from "./components/Gps";
import Online from "./components/Online";
import Project from "./components/Project";
import Advanced from "./components/Advanced";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Cookie from "./components/Cookie";
import Safe from "./components/Safe";
import Foundar from "./components/Foundar";
import Reporting from "./components/Reporting";
import Gaming from "./components/Gaming";
import HospitalErp from "./components/HospitalErp";
import Cane_ERP_And_Automation from "./components/Cane_ERP_And_Automation";
import Hotel_Management from "./components/Hotel_Management";
import School_ERP from "./components/School_ERP";
import Warehouse from "./components/Warehouse";
import Footer from "./components/Footer";
import CyberSecurity from "./components/CyberSecurity";
import SaaSDevelopment from "./components/SaaSDevelopment";
import BusinessProcessManagement from "./components/BusinessProcessManagement";
import GISApplication from "./components/GISApplication";
import TechicalDocument from "./components/TechicalDocument";
import DigitalMarketing from "./components/DigitalMarketing";
import RPAImplementaion from "./components/ERP/RPAImplementaion";
import BusinessIntelligence from "./components/BusinessIntelligence";
import MobileApplication from "./components/MobileApplication";
import SocialMediaDevelopment from "./components/SocialService.js";
import WebsiteDevelopment from "./components/WebsiteDevelopment";
import WebsiteTesting from "./components/WebsiteTesting";
import ECommerce from "./components/ECommerce";
import PageNotFound from "./components/PageNotFound";
import Blockchain from "./components/Blockchain";
import VoIP from "./components/VoIP";
import BankingPlatform from "./components/BankingPlatform";
import Smartcloud from "./components/Smartcloud";
import AIBenefits from "./components/AIBenefits";
import FinanceVertical from "./components/FinanceVertical";
import NeoBankingPlatform from "./components/NeoBankingPlatform";
import PlatformVariants from "./components/PlatformVariants";
import API_BasedPlatform from "./components/API_BasedPlatform";
import PlatformandBenefits from "./components/PlatformandBenefits";
import AITransformerCustomerJourney from "./components/AITransformerCustomerJourney";
import CustomerOnboarding from "./components/CustomerOnboarding";
import CustomerServices from "./components/CustomerServices"; // Adjust the path based on your file structure
import CustomerNurturing from "./components/CustomerNurturing";
import CyberSecurity1 from "./components/CyberSecurity1.js";
import ISPMonitoring from "./components/ISPMonitoring.js";

import Kyc from "./components/Kyc";
import Customer from "./components/Customer";
import Account from "./components/Account";
import Loan from "./components/Loan";
import Cards from "./components/Cards";
import Deposite from "./components/Deposite";
import Payment from "./components/Payment";
import Insurance from "./components/Insurance";
import PayrollWithIot from "./components/PayrollWithIot";

import CreateSavingAccount from "./components/CreateSavingAccount";
import StaffingOutsource from "./components/StaffingOutsource";
import BharatMake from "./components/BharatMake";
import KPO from "./components/KPO";
import ServiceModal from "./Layouts/ServiceModal";
import SmartAccounting from "./components/SmartAccounting";
import "system-font-css";
import SmartPOS from "./components/SmartPOS.js";

// import Why from "./components/why";
// import What from "./components/what";
// import How from "./components/How";
import Clientlist from "./components/Clientlist";
import TechnologyModal from "./Layouts/TechnologyModal";
import OnlineAnalytics from "./components/OnlineAnalytics";
import CloudeCiper from "./components/CloudCipher";
import AISmart from "./components/AISmart.js";
import IOT from "./components/IOT.js";
import SAP from "./components/SAP.js";
import Odoo from "./components/Odoo.js";
import MSDynamic from "./components/MSDynamic.js";
import SchoolErp from "./components/SchoolErp.js";
import GenAI from "./components/GenAI.js";
import Drone from "./components/Drone.js";
import Healthcare from "./components/Healthcare.js";
import Finance from "./components/Finance.js";
import Suply from "./components/Suply.js";
import Automativ from "./components/Automativ.js";

import Consumerproduct from "./components/Consumerproduct.js";
import Constructionproduct from "./components/Constructionproduct.js";
import Industialproduct from "./Industialproduct.js";
import Agricultualproduct from "./components/Agricultualproduct.js";
import SocialService from "./components/SocialService.js";
import Database from "./components/Database.js";
import ClodeDevops from "./components/cloudeDevops.js";
import Mobiletesting from "./components/Mobiletesting.js";
import SdWan from "./components/SdWan.js";
import Wanservice from "./components/Wanservice.js";
import Lanservice from "./components/Lanservice.js";
import FirewallService from "./components/FirewallService.js";
import MonitoringService from "./components/MonitoringService.js";
import AccountingFinance from "./components/AccountingFinance.js";
import Buisnessanalyst from "./components/BuisnessAnalyst.js";
import NfcService from "./components/NfcService.js";
import HpcService from "./components/HpcService.js";
import BusinessModal from "./Layouts/BusinessModal.js";
import Serviceproduct from "./components/Serviceproduct.js";

// app.js

document.addEventListener("DOMContentLoaded", function () {
  var element = document.getElementById("style");
  if (element !== null) {
    element.style.property = "value";
  }
});

// import ReactDOM from 'react-dom';
// import ReactGA from 'react-ga';
//   const TRACKING_ID = "6060473841"; // OUR_TRACKING_ID
//   ReactGA.initialize(TRACKING_ID);

//   const ContactUs = () => (
//     <div>
//       <h3>Contact Us</h3>
//     </div>
//   );

//   const home = () => (
//     <div>
//       <h2>Home</h2>
//     </div>
//   );

//   const about = () => (
//     <div>
//       <h2>About</h2>
//     </div>
//   );
//   const products = () => (
//     <div>
//       <h2>product</h2>
//     </div>
//   );
//   const service = () => (
//     <div>
//       <h2>Service</h2>
//     </div>
//   );

//   const Apps = () => {

//     useEffect(() => {
//       ReactGA.pageview(window.location.pathname + window.location.search);
//     }, []);

//   }
function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <NavBar />

      <Routes>
        <Route path="/consumer" element={<Consumerproduct />} />
        <Route path="/construction" element={<Constructionproduct />} />
        <Route path="/industrial" element={<Industialproduct />} />
        <Route path="/agricultural" element={<Agricultualproduct />} />
        <Route path="/businesses" element={<BusinessModal />} />
        <Route path="/service-product" element={<Serviceproduct/>}/>



        
        <Route path="/" element={<Home />} />
        <Route path="/platforms/PayrollWithIot" element={<PayrollWithIot />} />
        <Route path="/Technologies" element={<TechnologyModal />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/product" element={<Products />} />
        <Route path="/Services" element={<ServiceModal />} />
        <Route path="/platforms/Chat" element={<Chat />} />
        <Route path="/product/Gps" element={<Gps />} />
        <Route path="/platforms/Online" element={<Online />} />
        <Route path="/product/Project" element={<Project />} />
        <Route path="/service/advanced-toll-survey" element={<Advanced />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Cookie" element={<Cookie />} />
        <Route path="/Safe" element={<Safe />} />
        <Route path="/anout-us/founder" element={<Foundar />} />
        <Route path="/platforms/reporting" element={<Reporting />} />
        <Route path="/service/CyberSecurity1" element={<CyberSecurity1 />} />
        <Route path="/service/socialservice" element={<SocialService />} />
        <Route path="/service/cloudedevops" element={<ClodeDevops />} />
        <Route
          path="/platforms/SaasDevelopment"
          element={<SaaSDevelopment />}
        />
        <Route path="/service/gis-app" element={<GISApplication />} />
        <Route
          path="/service/technical"
          element={<TechicalDocument />}
        />
        <Route path="/service/acountandfinance" element={<AccountingFinance />} />
        <Route path="/service/buisnessanalyst" element={<Buisnessanalyst />} />
        <Route path="/service/nfcservice" element={<NfcService />} />
        <Route path="/service/Gps" element={<Gps />} />
        <Route path="/service/hpcservice" element={<HpcService />} />
        <Route
          path="/service/digital-marketing"
          element={<DigitalMarketing />}
        />
        <Route
          path="/platforms/erp-rpa-implementaion"
          element={<RPAImplementaion />}
        />
        <Route path="/platforms/AISmart" element={<AISmart />} />
        <Route
          path="/services/business-managment-process"
          element={<BusinessProcessManagement />}
        />
        <Route path="/platforms/ispmonitoring" element={<ISPMonitoring />} />;
        <Route
          path="/service/BusinessIntelligence"
          element={<BusinessIntelligence />}
        />
        <Route
          path="/service/MobileApplication"
          element={<MobileApplication />}
        />
        <Route
          path="/service/SocialMediaDevelopment"
          element={<SocialMediaDevelopment />}
        />
        <Route path="/service/blockchain" element={<Blockchain />} />
        <Route path="/platform/SmartAccounting" element={<SmartAccounting />} />
        <Route path="/platforms/VoIP" element={<VoIP />} />
        <Route
          path="/service/WebsiteDevelopment"
          element={<WebsiteDevelopment />}
        />
        <Route path="/service/WebsiteTesting" element={<WebsiteTesting />} />
        <Route path="/service/mobiletesting" element={<Mobiletesting />} />
        <Route path="/service/sdwanservice" element={<SdWan />} />
        <Route path="/service/wanservice" element={<Wanservice />} />
        <Route path="/service/lanservice" element={<Lanservice />} />
        <Route path="/service/firewallservice" element={<FirewallService />} />
        <Route path="/service/CyberSecurity" element={<CyberSecurity1 />} />
        <Route path="/service/monitoringservice" element={<MonitoringService />} />
        <Route path="/platforms/ECommerce" element={<ECommerce />} />
        <Route
          path="/platforms/BankingPlatform"
          element={<BankingPlatform />}
        />
        <Route path="/platforms/hospital-ERP" element={<HospitalErp />} />
        <Route
          path="/platforms/cane-ERP-automation"
          element={<Cane_ERP_And_Automation />}
        />
        <Route
          path="/platforms/hotel-management"
          element={<Hotel_Management />}
        />
        <Route path="/products/school-ERP" element={<School_ERP />} />
        <Route path="/products/warehouse" element={<Warehouse />} />
        <Route path="/platforms/Smartcloud" element={<Smartcloud />} />
        <Route
          path="/platforms/OnlineAnalytics"
          element={<OnlineAnalytics />}
        />
        <Route path="/platforms/smartpos" element={<SmartPOS />} />
        <Route path="/platforms/CloudCipher" element={<CloudeCiper />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/service/blockchain" element={<Blockchain />} />
        <Route path="/service/GamingAndVR" element={<Gaming />} />
        <Route path="/service/KnowledgeProcessOutsourcing" element={<KPO />} />
        <Route path="/service/iot" element={<IOT />} />
        <Route path="/service/database" element={<Database />} />
        <Route
          path="/service/Staffing/Consulting"
          element={<StaffingOutsource />}
        />
        <Route path="/navigate-your-next" element={<NavigateYourNext />} />
        {/* <Route path="/services/business-managment-process" element={<BusinessProcessManagement/>}/> */}
        <Route
          path="/service/BankingPlatform/AI/ML-Benefits"
          element={<AIBenefits />}
        />
        <Route
          path="/service/BankingPlatform/Finance-Vertical"
          element={<FinanceVertical />}
        />
        <Route
          path="/service/BankingPlatform/NeoBankingPlatform"
          element={<NeoBankingPlatform />}
        />
        <Route
          path="/service/BankingPlatform/Platform-Variants"
          element={<PlatformVariants />}
        />
        <Route
          path="/service/BankingPlatform/Neo-Banking-API-BAsedPlatform"
          element={<API_BasedPlatform />}
        />
        <Route
          path="/srevice/BankingPlatform/CustomerOnboarding"
          element={<CustomerOnboarding />}
        />
        <Route
          path="/service/BankingPlatform/CustomerService"
          element={<CustomerServices />}
        />
        <Route
          path="/service/BankingPlatform/CustomerNurturing"
          element={<CustomerNurturing />}
        />
        <Route path="/service/BankingPlatform/KYC" element={<Kyc />} />
        <Route
          path="/service/BankingPlatform/Customer"
          element={<Customer />}
        />
        <Route path="/service/Bankingplatform/Account" element={<Account />} />
        <Route path="/service/BankingPlatform/Loan" element={<Loan />} />
        <Route path="/service/BankingPlatform/Cards" element={<Cards />} />
        <Route
          path="/service/Bankingplatform/Deposite"
          element={<Deposite />}
        />
        <Route path="/service/BankingPlatform/Payment" element={<Payment />} />
        <Route
          path="/service/BankingPlatform/Insurance"
          element={<Insurance />}
        />
        <Route
          path="/service/BankingPlatform/CreatingSavingAccount"
          element={<CreateSavingAccount />}
        />
        <Route
          path="/service/BankingPlatform/Platform-And-Benefits"
          element={<PlatformandBenefits />}
        />
        <Route
          path="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          element={<AITransformerCustomerJourney />}
        />
        <Route path="/bharatmake" element={<BharatMake />} />
        {/* <Route path="/why" element={<Why/>}/> */}
        {/* <Route path="/what" element={<What/>}/> */}
        {/* <Route path="/How" element={<How/>}/> */}
        <Route path="/Clientlist" element={<Clientlist />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/service/BankingPlatform/KYC" element={<Kyc />} />
        <Route
          path="/service/BankingPlatform/Customer"
          element={<Customer />}
        />
        <Route path="/service/Bankingplatform/Account" element={<Account />} />
        <Route path="/service/BankingPlatform/Loan" element={<Loan />} />
        <Route path="/service/BankingPlatform/Cards" element={<Cards />} />
        <Route
          path="/service/Bankingplatform/Deposite"
          element={<Deposite />}
        />
        <Route path="/service/BankingPlatform/Payment" element={<Payment />} />
        <Route
          path="/service/BankingPlatform/Insurance"
          element={<Insurance />}
        />
        <Route
          path="/service/BankingPlatform/CreatingSavingAccount"
          element={<CreateSavingAccount />}
        />
        <Route
          path="/service/BankingPlatform/Platform-And-Benefits"
          element={<PlatformandBenefits />}
        />
        <Route
          path="/service/BankingPlatform/AI-Transformed-Customer-Journey"
          element={<AITransformerCustomerJourney />}
        />
        <Route path="/platforms/sap" element={<SAP />} />
        <Route path="/bharatmake" element={<BharatMake />} />
        <Route path="/platforms/odooservice" element={<Odoo />} />
        <Route path="/platforms/msdynamic" element={<MSDynamic />} />
        <Route path="/platforms/schoolerp" element={<SchoolErp />} />
        <Route path="/platforms/genai" element={<GenAI />} />
        <Route path="/businesses/drone" element={<Drone />} />
        <Route path="/businesses/healthcare" element={<Healthcare />} />
        <Route path="/businesses/finance" element={<Finance />} />
        <Route path="/businesses/suply" element={<Suply />} />
        <Route path="/businesses/Automative" element={<Automativ />} />
        <Route path="/businesses/ecommerce" element={<ECommerce />} />
        
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
