import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard1 from "./CaseStudyCard1.js";
import "./CaseStudyCard.css";

function CloudeCiper() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Seamless Integration",
      text: "Streamlined integration for seamless transitions and minimal downtime.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Scalability without Compromise",
      text: "CloudCipher ensures regulatory compliance, enabling businesses to securely manage sensitive data without legal concerns.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Customizable Security Policies",
      text: "CloudCipher empowers businesses with tailored security measures, offering granular control over encryption protocols and access permissions to align with unique requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Scalability without Compromise",
      text: "CloudCipher infrastructure ensures seamless scalability without compromising security or performance, catering to businesses of all sizes effortlessly",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "24/7 Monitoring and Support",
      text: 'To ensuring prompt resolution of security incidents and technical issues, with dedicated experts maintaining data integrity and availability."',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Managed Cloud Services </p>
      At Expertit, we prioritize secure, efficient, and collaborative work
      environments. CloudCipher offers a full suite of cloud services tailored
      to your business needs, ensuring data sovereignty and fostering teamwork.
      <hr />
      <br />
      <strong>Collaborative Editing</strong>
      SmartCloudintegrates with Collabora Online or ONLYOFFICE to provide
      collaborative editing of Word, Excel, and PowerPoint files.
      <br />
      <br />
      <strong> SmartCloudTalk</strong>Offers video conferencing, screen sharing,
      chat, and more. Email: Integrated mail client for handling personal and
      professional emails.
      <br />
      <br /> <strong>Security</strong>End-to-end encryption.
      <br />
      Two-factor authentication
      <br />
      Built-in ransomware protection.
      <br />
      Machine learning-based suspicious login detection. <br />
      <br />
      <strong>Apps</strong>A large library of apps to enhance functionality,
      from note-taking to music streaming. The SmartCloudApp Store offers
      various apps to extend the platform's capabilities.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Cloudcipher</div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px", fontStyle: "italic" }}
              >
                "The Ultimate Secure Collaboration Platform"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">Cloudcipher</div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
              CloudCipher is a comprehensive suite of managed cloud services
              that facilitate secure file sharing, real-time collaboration, and
              efficient communication within organizations.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/cloudcipher.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>File Management</strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Seamless access, synchronization, and sharing of your files
                across devices. Advanced backup and versioning ensure you never
                lose vital data.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
               
                paddingLeft: "15px",
              }}
            >
              Easy On-the-Go Access
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Mobile applications ensure you stay connected and productive.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Security & Privacy
            </strong>
            <p class="aligned-paragraph item3text">
              Ransomware protection and suspicious login detection. GDPR
              compliant with added privacy settings for data control.
              Token-based authentication for added security layers.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Collaboration Tools
            </strong>
            <p class="aligned-paragraph item3texts" style={{ color: "black" }}>
              Collaborative document editing. Organization-level chat for
              seamless internal communication. Personal and group calendars,
              contacts management, and task tracking.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              SmartChat & Bot Integration
            </strong>
            <p class="aligned-paragraph item3text">
              Engage with advanced chatbots for swift support and queries.
              Personal and group chats, ensuring efficient communication.
              Enhanced with end-to-end encryption for utmost privacy.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Ultra-Secure Communication
            </strong>
            <p class="aligned-paragraph item3text">
              Private VoIP calling: Connect without the fear of eavesdropping.
              Organization-level chats: Keep internal conversations guarded.
              Encrypted chat options: Every word you type is for your eyes and
              your intended recipient only.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Customizable & Extensible
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Enhance SmartCloud's capabilities with a myriad of extensions and
              plugins. Automate tasks and optimize data workflows.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="sliders">
          {/* Previous and Next buttons */}
          <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button>
          {/* Modal */}
          {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

          <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                    <p className="slidetext p-np slidecontent">{slide.text}</p>
                    {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      <div className="section5">
        <h1 className="section5heading">Case Studies  </h1>
        <div className="section5jsxcard-3">
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg1.jpg"
            title="Modernization"
            description="CloudCipher Empowering the Revenue and Tax Collection Department"
            linkUrl="/platform/cloudecipher/casestudy-Empowering-taxcollection"
          />
          <CaseStudyCard1
            imageUrl="/Images/casestudyimg2.jpg"
            title="Data Security"
            description="CloudCipher Enhancing Data Integrity in PharmaCo's R&D Division"
            linkUrl="/plaform/cloudcipher/casestudy-Enhancing-Data-Integrity"
          />

          <CaseStudyCard1
            imageUrl="/Images/casestudyimg4.jpg"
            title="Bank Security"
            description="CloudCipher Securing Operations at Bank"
            linkUrl="/platform/cloudcipher/casestudy-Securing-Operations-Bank"
          />
        </div>
      </div>

      {/* section 6 */}
    </div>
  );
}

export default CloudeCiper;
