import React, { useState } from "react";

import { Link } from "react-router-dom";

import "./BusinessModal.css";
import CaseStudyCard from "../components/CaseStudyCard";

export default function BusinessModal() {
  return (
    <div>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <h2 className="submenu-h2-heading mb10">Businesses </h2>
      </div>
      <div className="section5">
        {/* <h1 className="section5heading">Products</h1> */}
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/shopping.png"
            title="Consumer"
            description=""
            linkUrl="/consumer"
          />
          <CaseStudyCard
            imageUrl="/Images/Agriculture.jpg"
            title="Agricultural"
            description=""
            linkUrl="/agricultural"
          />
          <CaseStudyCard
            imageUrl="/Images/Construction.jpg"
            title="Construction"
            description=""
            linkUrl="/construction"
          />
          <CaseStudyCard
            imageUrl="/Images/Industrial.jpg"
            title="Industrial"
            description="" 
            linkUrl="/industrial"
          />
          <CaseStudyCard
            imageUrl="/Images/Services.jpg"
            title="Services"
            description="" 
            linkUrl="/services"
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
{/* 
      <div id="business-container" className="container">
        <div className="service-row">
          <div className="service-column">
            <div className="row">
              <Link to="/businesses/drone" className="abc">
                Drone Solution
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/finance" className="abc">
                Financial Services
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/bo-bi" className="abc">
                Public Sector
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/Automative" className="abc">
                Automotive
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/customization-consulting" className="abc">
                {" "}
                Education
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
          </div>

          <div className="service-column">
            <div className="row">
              <Link to="/businesses/healthcare" className="abc">
                Helthcare
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/bpo-kpo" className="abc">
                Information Services
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/businesses/suply" className="abc">
                Logistics & Supplychain
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="businesses/ecommerce" className="abc">
                Retail & Ecommerce
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
            <div className="row">
              <Link to="/ecu-iot" className="abc">
                Digital Marketing & Strategy
              </Link>
            </div>{" "}
            <hr className="media-query-hr" />
          </div>
        </div>
      </div> */}
    </div>
  );
}
