import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function SAP() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise Across Domains",
      text: "Our team brings in-depth knowledge across ADM, ERP, Cloud, and Testing, ensuring a holistic approach to managing your IT landscape. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions",
      text: " We understand that one size does not fit all, offering tailored services that align with your specific business requirements.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Proactive Management",
      text: " Our proactive approach to maintenance and support helps in identifying and resolving issues before they impact your operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on Innovation",
      text: "While we manage your current technology needs, we also keep an eye on emerging technologies that can further enhance your business operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Reliable Support",
      text: '  With our round-the-clock support, we ensure your business operations are always running without any hitches.  "',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <div className="modal-np">
        <div className="modal-sub-sk">
          <div className="row-sk">
            <div className="card-con-sk">
              <h4>Purpose of the SAP Services</h4>
              <p>
                At ExpertIT, we're committed to empowering businesses with
                comprehensive SAP solutions that streamline operations, enhance
                decision-making, and drive innovation. As seasoned experts in
                SAP ERP, system integration, and IoT technology, our mission is
                to deliver end-to-end managed services that transform your
                technological landscape into a robust platform for growth and
                efficiency.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">SAP Managed Services </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Streamlining Success – Expert SAP Solutions for Business
                Excellence"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Managed Services ? </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              OMSK specializes in offering a wide array of SAP services
              tailored to meet the dynamic needs of modern businesses. Our
              offerings encompass SAP ERP implementation and customization,
              BusinessObjects/Business Intelligence (BO/BI) services, ABAP
              development across all modules, SAP Business One (B1) integration,
              and pioneering integration of IoT with SAP. With deep industry
              expertise and a commitment to excellence, we provide technical and
              functional support at every stage, ensuring seamless integration,
              optimal performance, and strategic advantage.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/sap-business-software-infographics_116137-269.avif"
                alt="Description of the image"
                style={{ borderRadius: "50%" }}
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Features</div>

        <div class="section3parts">
          <div class="content-np item31">
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                System Integration for SAP and Industrial Automation
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Bridging the gap between SAP ERP systems and industrial
                automation, enhancing operational efficiency and data accuracy.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              SAP ERP Implementation and Customization
            </strong>
            <div class=" item31-conent-img  "></div>
            <p class="aligned-paragraph item3text " style={{ color: "black" }}>
              Tailoring SAP ERP solutions to fit your business needs, ensuring a
              seamless integration with existing processes.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              BO/BI Services
            </strong>
            <p class="aligned-paragraph item3text">
              Leveraging SAP BusinessObjects and Business Intelligence to
              provide powerful analytics and reporting tools, driving informed
              decision-making.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              ABAP Development
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Offering custom ABAP development services for all SAP modules,
              ensuring your SAP system meets your specific functional
              requirements.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              SAP B1 Integration
            </strong>
            <p class="aligned-paragraph item3text">
              Seamlessly integrating SAP Business One into your business
              environment, providing a comprehensive solution for small to
              medium-sized enterprises.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              IoT Integration with SAP
            </strong>
            <p class="aligned-paragraph item3text">
              Pioneering the integration of IoT technologies with SAP systems,
              enhancing real-time data collection, analysis, and automation.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Cloud Integration with SAP
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Harnessing the power of cloud computing to extend SAP
              functionalities beyond traditional boundaries. Our expertise in
              cloud integration enables seamless connectivity with various cloud
              platforms, enabling scalability, flexibility, and
              cost-effectiveness in deploying and managing SAP solutions.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t "> USP of SAP Services </h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Comprehensive Expertise{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Comprehensive Expertise</h2>
                      <p>
                        Our extensive knowledge in SAP ERP, coupled with
                        specialization in system integration and IoT, positions
                        us as leaders in the field.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Customized Solutions{" "}
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customized Solutions</h2>
                      <p>
                        We pride ourselves on delivering tailored SAP solutions
                        that are precisely aligned with our clients' business
                        goals and operational needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Innovative Integration{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        src="/Images/artificial-intelligence-sa.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Innovative Integration</h2>
                      <p>
                        {" "}
                        Our pioneering integration of IoT with SAP sets new
                        standards for operational efficiency and data
                        intelligence.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          End-to-End Managed Services{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/route.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>End-to-End Managed Services</h2>
                      <p>
                        From initial consultation to ongoing support, we offer
                        complete managed services for SAP platforms at both
                        technical and functional levels.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Proven Track Record
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/social-care.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Proven Track Record</h2>
                      <p>
                        Our successful implementations and satisfied clients
                        across diverse industries in India testify to our
                        expertise and commitment to excellence.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Continuous Improvement and Optimization{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/presentation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2> Continuous Improvement and Optimization</h2>
                      <p>
                        We are committed to ongoing refinement and optimization
                        of SAP solutions to keep pace with evolving business
                        needs and technological advancements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
                </div>
            </div>
        </div>
        </div> */}
              {/* <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
              {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
            </div>
          </div>
        </div>

        {/* <div className='sliders'> */}
        {/* Previous and Next buttons */}
        {/* <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

        {/* <CarouselSlider/> */}
      </div>

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Strategic Consultation"
            description="Engaging with clients to understand their business challenges and objectives, forming the foundation of our tailored SAP solutions.   "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Solution Design and Implementation"
            description="Utilizing our deep expertise to design and implement SAP solutions that drive value, supported by seamless system integration and customization.  "
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Continuous Optimization and Support"
            description=" Providing ongoing optimization, maintenance, and support services to ensure the SAP systems evolve with the business, maximizing ROI.  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Innovative Enhancements"
            description=" Continually exploring and integrating innovative technologies like IoT into the SAP landscape to stay ahead of the digital transformation curve. "
            // linkUrl=""
          />

          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

          {/* section 6 */}
        </div>
      </div>
    </div>

    //   </div>
  );
}

export default SAP;
