import React from 'react'
// import './Bharatmake.css';
export default function BharatMake() {
  return (
    <div>
      <div id="carouselExampleCaptions" className="carousel slide">

<div className="carousel-inner">
 <div className="carousel-item active">
   <img
     src="/Images/bharatmake.png"
     className="d-block w-100"
     alt="slider first"
   />
   {/* <div className="carousel-caption">
     <h3>Native Mobile App Development</h3>
     <p>Why Native?</p>
     <p>
       Native apps are built specifically for a single platform (iOS or Android) using platform-specific programming languages (Swift/Objective-C for iOS, Java/Kotlin for Android). They offer several advantages:
     </p>
     <ul>
       <li>Performance: Native apps provide the best performance and user experience due to their close integration with the platform's capabilities.</li>
       <li>Access to Features: Native development allows direct access to device features like camera, GPS, push notifications, and hardware acceleration.</li>
       <li>UX/UI: Native apps can be designed with platform-specific UI guidelines, resulting in a consistent and familiar user interface.</li>
     </ul>
   </div> */}
 </div>
 <div className="carousel-item">
   <img
     src="/Images/bharatmake-devnagiri.png"
     className="d-block w-100"
     alt="slider second"
   />
   {/* <div className="carousel-caption">
     <h3>Cross-Platform Mobile App Development</h3>
     <p>Why Cross-Platform?</p>
     <p>
       Cross-platform apps are developed using frameworks like React Native, Flutter, or Xamarin, allowing you to create a single codebase that runs on multiple platforms:
     </p>
     <ul>
       <li>Cost Efficiency: Developing for multiple platforms using a single codebase significantly reduces development time and costs.</li>
       <li>Faster Deployment: Cross-platform apps can be deployed simultaneously on multiple platforms, reducing time-to-market.</li>
       <li>Maintenance: Updates and maintenance can be applied to the single codebase, saving time compared to maintaining separate native apps.</li>
     </ul>
   </div> */}
   </div>

   
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
 <span className="carousel-control-prev-icon" aria-hidden="true"></span>
 <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
 <span className="carousel-control-next-icon" aria-hidden="true"></span>
 <span className="visually-hidden">Next</span>
</button>

</div>



<div className="cart">
 <div className='div'>
   <h2 className='headings-point' style={{fontFamily:'Oswald'}}>BharatMake an Initiative Of Hindustan Informatics</h2>
   <p style={{fontSize:'22px',paddingLeft:'10%',fontFamily:'myriad-pro, Arial',color:'#7E7E7E'}}>A Company BharatMake LLC, backed by Hindustan Informatics Group, is a e commerce platform for Made In India Products</p>
   <ul>
     <li className='li-ul'>Goal to Promote and sale Indian DNA products with global level marketing strategy .</li>
     <li className='li-ul'>Show the Indian Heritage of creating Innovative products which are best in class since last centuries. </li>
     <li className='li-ul' >Online Shopping platform – Heritage Fashion items like cloths, natural cosmetics, wearables ,Heridatory healthy food items, Spices,  and Idols (Bronz, Crystal, Copper, etc),Metallic and non metalic  Artifacts Store app brings you thousands of unique, famous and quality groceries prepared in several regions of India, List of GI tag and certified items, </li>
     <li className='li-ul' > Our goal is to create a global platform for local groceries, specialized and famous artifacts which are identity/icon of region . We are bringing those unique products to your doorstep with special care and attention to serve your need to make it available on global platform. 
 </li>
     <li className='li-ul'>Festival focused Product targeting, Effective Quality Checks, Efficient Supply chain and logistics Policy.</li>
     <li className='li-ul'>Worldwide launch in USA, UAE, Oman, Thailand.</li>
     <li className='li-ul'>Along with regular Indian Products its an Artifact gallery. </li>
     <li className='li-ul'>We are planning to go global with this. 
</li>

   </ul>
 </div>
</div>

    

   </div>
    </div>
  )
}
