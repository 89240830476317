import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import SOP from "./SOP.js";
// import "./cyberCard.css";
import Accordians from "./Accordians.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Buisnessanalyst() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise",
      text: "Our certified cyber security professionals possess extensive knowledge and experience in identifying and mitigating security risks. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Comprehensive Approach",
      text: "We examine every layer of your digital infrastructure to provide holistic security solutions.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cutting-Edge Tools",
      text: "We leverage the latest security tools and methodologies to ensure accurate assessments. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Custom Solutions",
      text: "We tailor our recommendations to your organizations specific needs and industry requirements.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Regulatory Compliance",
      text: " We help you meet industry standards and compliance regulations related to data protection and cyber security.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "How can a business analyst improve my company's performance?  ",
      content:
        "By identifying areas of improvement, optimizing processes, and implementing solutions that lead to cost reduction, increased revenue, and improved customer satisfaction.    ",
    },
    {
      title: "What types of businesses can benefit from your services?  ",
      content:
        "Our services are valuable to any business looking to refine its operations, from startups to multinational corporations across various industries. ",
    },
    {
      title: "How do you ensure the recommendations are implemented effectively? ",
      content:
        "We offer end-to-end support, from planning through to implementation and post-implementation review, ensuring recommendations deliver the desired impact.   ",
    },
    
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">
        Purpose and Vision of our Business Analyst services{" "}
      </p>

      <hr />

      <div className="modal-sub-sk">
        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Purpose</h5>
            <p>
              To provide expert business analysis services that translate
              complex business needs into actionable strategies, driving
              efficiency, innovation, and market growth.
            </p>
          </div>
        </div>

        <div className="row-sk">
          <div className="card-con-sk-4">
            <h5>Vision</h5>
            <p>
              To be the premier partner for businesses seeking to navigate the
              complexities of the market through in-depth analysis, strategic
              planning, and data-driven decision-making.
            </p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/firstcomponant.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1">Business Analyst services </div>

              <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Transform Insights into Outcomes"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",
              }}
            >
              Business Analytics Solutions offers professional business analyst
              services, focusing on understanding the intricacies of your
              business environment and delivering comprehensive solutions. We
              specialize in market research, process improvement, data analysis,
              and strategic planning to help you achieve your business
              objectives.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/data-research.png"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div class="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section class="section3">
        <div class="cont2-title title ">Our Offerings</div>

        <div class="section3parts">
          <div
            class="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Market Research and Analysis
              </strong>
              <p
                class="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Gaining insights into market trends, customer behavior, and
                competitive landscapes.
              </p>
            </div>
          </div>

          <div class="content-np item322">
            <strong
              class="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Process Improvement
            </strong>
            <div class=" item31-conent-img  "></div>
            <p
              class="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Streamlining operations to enhance efficiency and productivity.
            </p>
          </div>

          <div class="content-np item33">
            <strong
              class="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Data Analysis and Interpretation
            </strong>
            <p class="aligned-paragraph item3text">
              Turning data into actionable intelligence to support strategic
              decisions.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div class="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Project Management
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Overseeing projects from inception to completion, ensuring
              objectives are met on time and within budget.
            </p>
          </div>
          <div class="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Change Management
            </strong>
            <p class="aligned-paragraph item3text">
              Assisting organizations in managing change effectively to minimize
              disruption.
            </p>
          </div>

          <div class="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Technology Implementation Support
            </strong>
            <p class="aligned-paragraph item3text">
              Facilitating communication and collaboration between various
              stakeholders to ensure alignment and successful implementation of
              projects, initiatives, or organizational changes.
            </p>
          </div>

          <div class="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Stakeholder Engagement
            </strong>
            <p class="aligned-paragraph item3text" style={{ color: "black" }}>
              Ensuring that individuals with disabilities can effectively use
              your website or application by evaluating factors like screen
              reader compatibility, keyboard navigation, color contrast, and
              image alternative text, in compliance with standards like WCAG.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div class="section4">
        {/* <div className='sliders'>
 
  <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of Business Analyst </h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expert Analysis{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expert Analysis</h2>
                      <p>
                        Our business analysts bring a wealth of knowledge and
                        expertise to identify and solve complex business
                        challenges.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Customized Strategies
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customized Strategies</h2>
                      <p>
                        Tailored solutions that resonate with your business's
                        unique situation and objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Data-Driven Decision Making{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img className="img-4" src="/Images/approch.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Data-Driven Decision Making</h2>
                      <p>
                        Leveraging cutting-edge analytical tools to inform
                        strategies and improve outcomes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper card-wrapper-4">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2 className="h2-4-sk">
                          Collaborative Approach
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-4"
                        src="/Images/business-network.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Collaborative Approach</h2>
                      <p>
                        Working closely with your team to understand your
                        business and create a cohesive strategy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* section 6 */}

      <div className="newsection5-np">
        <h1 className="section5heading"> Our SOP for this Service</h1>
        <div className="section5newgrid">
          <SOP
            headding="Needs Assessment"
            text=" Deep dive into your business processes, goals, and challenges."
            img="/Images/icons8-requirement-48.png"
          />
          <SOP
            headding="Research and Analysis"
            text=" Comprehensive market and internal analysis to uncover insights. "
            img="/Images/test.png"
          />
          <SOP
            headding="Solution Design"
            text="Development of strategic plans and solutions based on findings. "
            img="/Images/icons8-test-60.png"
          />
          <SOP
            headding="Implementation Support"
            text="Hands-on support during the execution of recommended strategies. "
            img="/Images/report.png"
          />
          <SOP
            headding="Performance Tracking"
            text="Monitoring and evaluating the impact of implemented solutions. "
            img="/Images/planning.png"
          />
          <SOP
            headding="Feedback and Optimization"
            text=" Iterative improvements based on feedback and performance data. "
            img="/Images/icons8-date-to-48.png"
          />
        </div>
      </div>
      <div className="section5">
        <h1 className="section5heading">Use Cases </h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Elevating E-commerce through Targeted Customer Insight Analysis"
            description="  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Optimizing Supply Chain Operations for a Pharmaceutical Leader"
            description=""
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Implementing Cutting-Edge Tech for a Financial Services Firm" 
            description=" "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Strategic Overhaul for a Boutique Hotel Chain's Market Positioning"
            description="" 
            // linkUrl=""
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
      
      
      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading">FAQ's for this service</h1>
          <Accordians items={items} />
        </div>
      </section>
    </div>
  );
}

export default Buisnessanalyst;
