import React from 'react'
import "./template.css";
import CaseStudyCard from './CaseStudyCard';

export default function Consumerproduct() {
  return (
    <div>
     <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/consumer-bag.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 black-color">
              Consumer
              </div>

              {/* <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empower Your Digital Horizon – Seamless Cloud Integration and
                Management"
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21" style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",paddingBottom:'30px',gridTemplate:'none'
              }}>
          {/* <!-- left part --> */}
          OMSK is a provider of consumer goods from Asia and Europe. With a focus on fast moving consumer goods, food services, luxury goods as well as fashion and lifestyle products and hair and skin cosmetics. 

Our extensive service also provide service of offer extends from product feasibility studies and registration to importation, customs clearance, marketing, sales, field merchandising and promoter services, warehousing, physical distribution, invoicing, cash collection, after-sales and other value added services to another OEMs.

Our expertise and broad local knowledge, together with our infrastructure, enable us to better understand our business partners’ needs and to deliver customized solutions to grow their businesses.
From our distribution centers in Asia, we provide fast moving consumer goods (FMCG) companies with direct access to retail channels and into healthcare outlets (hospitals, clinics and drug dispensing pharmacies) if required. Our marketing and sales experts have a proven track record in improving the brand awareness, brand value and market share of our clients’ products. We also cater to the rapidly growing hospitality industry in the region.
Serving hundreds of boutiques, shops-in-shops and brand counters, we have a proven track record as a brand builder in luxury goods. 
        

          {/* right part */}
          {/* <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/devops.jpg"
                alt="Description of the image"
                style={{ width: "450px", height: "450px" }}
              />
            </div>
          </div> */}
        </div>
          {/* <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div> */}
      </div>
     <div className="section5">
        {/* <h1 className="section5heading">Consumer</h1> */}
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/skin-care.jpg"
            title="Skin Care"
            description={<ul>
               <li>Herb Cream</li>
              <li>Herb Serum</li>
            </ul>}
           
          />
          <CaseStudyCard
            imageUrl="/Images/hair-care.jpg"
            title="Hair Care"
            description={<ul>
               <li>Samay Hair Oil</li>
              <li>Satmay Hair Shampoo</li>
              <li>Satmay Conditioner</li>
            </ul>}
            // linkUrl="/agricultural"
          />
          <CaseStudyCard
            imageUrl="/Images/can-food.jpg"
            title="Can Food"
            description={<ul>
             <li>Yoyo Coconut Water</li>
              <li>Yoyo Jelly</li>

            </ul>}
            // linkUrl="/construction"
          />
          <CaseStudyCard
            imageUrl="/Images/services-consumer.jpg"
            title="Services"
            description={<ul>
             <li>Security</li>
              <li>Garden Maintenance</li>
              <li>Office Keeper</li>
              <li>House Keeper</li>
              <li>Personal Body Guards</li>

            </ul>} 
            // linkUrl="/industrial"
          />
          {/* <CaseStudyCard
            imageUrl="/Images/Services.jpg"
            title="Services"
            description="" 
            linkUrl="/services"
          /> */}
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
     {/* <section className="section5">
        <h1 className="section5heading">Consumer</h1>
        <div className="section5jsxcard">
          <div className="case-study-card">
            <img
              className="case-study-img"
              src="/Images/casestudyimg1.jpg"
              alt="Skin Care"
            />
            <h2 className="case-study-title">Skin Care</h2>
            <ul className="case-study-list">
              <li>Herb Cream</li>
              <li>Herb Serum</li>
            </ul>
          </div>

          <div className="case-study-card">
            <img
              className="case-study-img"
              src="/Images/casestudyimg2.jpg"
              alt="Hair Care"
            />
            <h2 className="case-study-title">Hair Care</h2>
            <ul className="case-study-list">
              <li>Samay Hair Oil</li>
              <li>Satmay Hair Shampoo</li>
              <li>Satmay Conditioner</li>
            </ul>
          </div>

          <div className="case-study-card">
            <img
              className="case-study-img"
              src="/Images/casestudyimg3.jpg"
              alt="Can Food"
            />
            <h2 className="case-study-title">Can Food</h2>
            <ul className="case-study-list">
              <li>Yoyo Coconut Water</li>
              <li>Yoyo Jelly</li>
            </ul>
          </div>

          <div className="case-study-card">
            <img
              className="case-study-img"
              src="/Images/casestudyimg4.jpg"
              alt="Services"
            />
            <h2 className="case-study-title">Services</h2>
            <ul className="case-study-list">
              <li>Security</li>
              <li>Garden Maintenance</li>
              <li>Office Keeper</li>
              <li>House Keeper</li>
              <li>Personal Body Guards</li>
            </ul>
          </div>
        </div>
      </section> */}
      
    </div>
  )
}
