import React from "react";
import { Link } from "react-router-dom";
import "./ProductModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faBriefcase,
  faLightbulb,
  faShoppingCart,
  faTractor,
  faHardHat,
  faTools,
  faIndustry,
} from "@fortawesome/free-solid-svg-icons";

export default function ProductModal() {
  return (
    <>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <h2 className="submenu-h2-heading">Products</h2>
      </div>
      <div className="card-Product">
        <div className="Product-Product">
        <div className="Product-row" style={{ justifyContent: "flex-start" }}>
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faShoppingCart} className="Product-icon" />
                <div className="heading-h6">Consumer</div>
              </div>
            </div>

            <div className="Product-column">
              <ul className="list-inline">
               
                <li className="Product-list-item">Skin Care
                <ul><li className="Product-list-item1">Brand OEM service</li>
                <li className="Product-list-item1">Herb Cream</li>
                <li className="Product-list-item1">Herb serum</li></ul></li>
                <li className="Product-list-item">Hair care
                <ul><li className="Product-list-item1">Samay Hair oil</li>
                <li className="Product-list-item1">Satmay Hair Shampoo</li>
                <li className="Product-list-item1">Satmay Conditioner</li>
               </ul></li>
               <li className="Product-list-item">Can food 
                <ul><li className="Product-list-item1">Yoyo coconut water</li>
                <li className="Product-list-item1">Yoyo Jelly</li>
                
               </ul></li>
               <li className="Product-list-item">Services
                <ul><li className="Product-list-item1">Security</li>
                <li className="Product-list-item1">Garden Maintenance</li>
                <li className="Product-list-item1">Office keeper</li>
                <li className="Product-list-item1">House keeper</li>
                <li className="Product-list-item1">Personal body guards</li>

                
               </ul></li>
                

              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

          <div className="Product-row" style={{ justifyContent: "flex-start" }}>
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faTractor} className="Product-icon" />
                <div className="heading-h6">Agricultural</div>
              </div>
            </div>

            <div className="Product-column">
              <ul className="list-inline">
              <li className="Product-list-item">Smart Farm
                <ul><li className="Product-list-item1">IOT</li>
                

                
               </ul></li>
               <li className="Product-list-item">Plants
                <ul><li className="Product-list-item1">Fruits Plants tree</li>
                

                
               </ul></li>
               <li className="Product-list-item">Equipment's
                <ul><li className="Product-list-item1">Seed feeder accessories</li>
                

                
               </ul></li>
               <li className="Product-list-item">Smart tech
                <ul><li className="Product-list-item1">Autonomous Agricultural Robot for Weeding, Seeding, Spraying, Planting</li>
              </ul></li>
               
               
              
               
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

          <div className="Product-row" style={{ justifyContent: "flex-start" }}>
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faTools} className="Product-icon" />
                <div className="heading-h6">Construction</div>
              </div>
            </div>

            <div className="Product-column">
              <ul className="list-inline">
              <li className="Product-list-item">Safety Equipment's
                <ul><li className="Product-list-item1">Safety Net</li>
                <li className="Product-list-item1">Safety Equipment's</li>
              </ul></li>                         
                <li className="Product-list-item">Interior design parts</li>
                <li className="Product-list-item">Consumer parts</li>

              </ul>
            </div>
          </div>
          <div className="menu-seprator"></div>
          <div className="Product-row" style={{ justifyContent: "flex-start" }}>
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faIndustry} className="Product-icon" />
                <div className="heading-h6">Industrial</div>
              </div>
            </div>

            <div className="Product-column">
              <ul className="list-inline">
                <li className="Product-list-item">
                  <Link to="">Software</Link>
                </li>
                <li className="Product-list-item">services
                <ul><li className="Product-list-item1">Quality control</li>
                <li className="Product-list-item1">Material handling</li>
                <li className="Product-list-item1">Installation</li></ul></li>
                <li className="Product-list-item">Consumer parts
                <ul><li className="Product-list-item1">DI water</li>
                <li className="Product-list-item1">Card board</li>
                <li className="Product-list-item1">Machining</li>
                <li className="Product-list-item1">Mass production jobs</li></ul></li>
                <li className="Product-list-item">Safety Equipment's</li>
                

              </ul>
            </div>
          </div>
          <div className="menu-seprator"></div>

          {/* Add more Product rows as needed */}

        </div>
      </div>
    </>
  );
}
