import React from "react";

import { Link } from "react-router-dom";

import "./service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faBug,
  faBullhorn,
  faChartBar,
  faCode,
  faCogs,
  faFileAlt,
  faLightbulb,
  faIndustry,
  faDatabase,
  faTasks,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

export default function ServiceModal() {
  return (
    <>
      <div className="col-md-12 col-sm-12 col-xs-12">
        <h2 className="submenu-h2-heading ">Services</h2>
      </div>
      <div className="card1">
        <div className="service-section">
          {/* Digital Marketing/Strategy */}

          <div className="service-row">
            <div className="service-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faBullhorn} className="section-icon" />

                <div className="heading-h6">Digital Marketing/Stratergy</div>
              </div>
            </div>

            <div className="service-column">
              <ul className="list-inline mb0">
                <li className="experience-list-item">
                  <Link to="/service/digital-marketing">
                    Digital Marketing and strategic Services
                  </Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/socialservice">
                    Social Media Services
                  </Link>
                </li>

                {/* <li className="experience-list-item">
                <Link to="/service/SocialMediaDevelopment">
                  Social Media Service
                </Link>
              </li> */}
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

          {/* Software and Web Development */}

          <div className="service-row">
            <div className="service-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faCode} className="section-icon" />

                <div className="heading-h6">ADM Services</div>
              </div>
            </div>

            <div className="service-column">
              <ul className="list-inline mb0">
                <li className="experience-list-item">
                  <Link to="/service/WebsiteDevelopment">Website</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/gis-app">GIS Application</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/mobileapplication">
                    Mobile Application
                  </Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/GamingAndVR">Unity Development</Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/database">Database / Data Warehouse</Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/blockchain">Blockchain</Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/cloudedevops">
                    Cloud and DevOps Managed Services
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* LetUs Tech */}

          <div className="menu-seprator"></div>

          {/* Software Testing */}

          <div className="service-row">
            <div className="service-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faBug} className="section-icon" />

                <div className="heading-h6"> Testing</div>
              </div>
            </div>

            <div className="service-column">
              <ul className="list-inline mb0">
                <li className="experience-list-item">
                  <Link to="/service/websitetesting">
                    Web Site & Web Application Testing
                  </Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/mobiletesting">
                    Mobile Application Testing
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>

          {/* <li className="Product-list-item">
                <Link to="/Products/Data Warehouse and Data Mining/Analytics.html">
                Licence Implementation
                </Link>
              </li> */}

          {/* <li className="Product-list-item">
                <Link to="/Products/Integration with Machine (INDUSTRY 4.0).html">
                Integration with Machine (INDUSTRY 4.0)
                </Link>
              </li> */}

          {/* <li className="Product-list-item">
                <Link to="/product/Gps">
                GPS based vehicle tracking
                </Link>
              </li> */}

          <div
            className="Product-row"
            style={{ justifyContent: "flex-start", marginTop: "20px" }}
          >
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faTasks} className="Product-icon" />

                <div className="heading-h6">Networking And Cyber Security</div>
              </div>
            </div>
            <div className="service-column">
              <ul className="list-inline mb0">
                <li className="experience-list-item">
                  <Link to="/service/sdwanservice">SD-WAN Services</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/wanservice">WAN Services</Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/lanservice">LAN Services</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/firewallservice">Firewall Services</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/CyberSecurity">Cybersecurity Services</Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/monitoringservice"> Monitoring Services</Link>
                  
                </li>

                
                

                
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>
          <div
            className="Product-row"
            style={{ justifyContent: "flex-start", marginTop: "20px" }}
          >
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faBriefcase} className="section-icon" />

                <div className="heading-h6">Staff / Consulting Outsourcing</div>
              </div>
            </div>
            <div className="service-column">
              <ul className="list-inline mb0">
                <li className="experience-list-item">
                  <Link to="/service/technical">Technical</Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/acountandfinance">
                    Accounting And Finance
                  </Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/buisnessanalyst">Analyst</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="menu-seprator"></div>
          <div
            className="Product-row"
            style={{ justifyContent: "flex-start", marginTop: "20px" }}
          >
            <div className="Product-column">
              <div className="icon-and-text">
                <FontAwesomeIcon icon={faBriefcase} className="section-icon" />

                <div className="heading-h6">IOT</div>
              </div>
            </div>
            <div className="service-column">
              <ul className="list-inline mb0">
                <li className="experience-list-item">
                  <Link to="/service/nfcservice">
                    NFC and Fast Tag Integrators
                  </Link>
                </li>

                <li className="experience-list-item">
                  <Link to="/service/Gps">
                    GPS / NAVIC Tracking System
                  </Link>
                </li>
                <li className="experience-list-item">
                  <Link to="/service/hpcservice">Distributed & HPC</Link>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
