import { faHospital, faIndustry, faLaptop, faShoppingCart, faUniversity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './RPI.css';

export default function RPAImplementaion() {
  return (
    <>
    <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
    <section id="sml_ht_home_banner">
      <article className="container">
        <div className="row">
          <div className="col-md-9 col-sm-12 col-xs-12 ">
            <h1 className="h2-heading white-color l-top">
              ERP/RPA Implementaion
            </h1>
          </div>
        </div>
      </article>
    </section>
  </div>
  <div id='box1' className="box">
  <div className='container'>
  <h2>Welcome to Expert IT, your trusted partner for cutting-edge Enterprise Resource Planning (ERP) and Robotic Process Automation (RPA) solutions. With a commitment to streamlining operations, enhancing efficiency, and enabling digital transformation, we offer comprehensive services that cater to your business's unique needs. </h2>

  </div>
  </div>
  <section class="pt75">
  <article class="container">
    <div class="row">
        
    <h1 className='text-center'>Our Services </h1>
      <div class="col-md-6 col-sm-12 col-xs-12 text-center-xs wow fadeInLeft card" data-wow-delay="0.6s"> 
      
      <h4>ERP Implementation and Development  </h4>
      <p>Transform your business with our ERP solutions that provide seamless integration, real-time insights, and enhanced productivity. Our ERP services encompass: </p>
      <ul>
            <li>1. Custom ERP Development: Tailored ERP solutions designed to align with your business processes and optimize operations. </li>
            <li>2. SAP and MS Dynamics Support: Expertise in implementing, customizing, and supporting SAP, Microsoft Dynamics, and other industry-leading ERP platforms.</li>
            <li>3. Industrial Automation Integration: Integrate your ERP system with industrial automation solutions to enhance manufacturing efficiency and data accuracy. </li>
          </ul>
      </div>
      <div class="col-md-6 col-sm-12 col-xs-12 mb-sm-20 wow fadeInRight card" data-wow-delay="0.6s">
      <h4>RPA Implementation and Development   </h4>
      <p>Unlock new levels of efficiency through our RPA solutions that automate repetitive tasks and optimize workflows. Our RPA services include:  </p>
      <ul>
            <li>1. Custom RPA Development: Develop and deploy RPA bots tailored to your specific processes, enhancing accuracy and reducing human effort. </li>
            <li>2. Process Analysis and Automation: Identify processes suitable for automation, streamline workflows, and achieve cost savings. </li>
            <li>3. Integration with ERP: Seamlessly integrate RPA bots with your ERP system for end-to-end process optimization.  </li>
          </ul>
       </div>
    </div>
  </article>
</section>
<div className='d'
    
    >
      <h4 className='heading-h4'>Why Choose OMSK CO,. LTD Company? </h4>
      <ul>
        <li className='li text-start' style={{color:'black', marginTop:'30px'}}>Expertise: Our experienced team brings deep expertise in ERP and RPA development, implementation, and integration. </li>
        <li className='li text-start'style={{color:'black', marginTop:'30px'}} >Custom Solutions: We understand that each business is unique. Our solutions are tailored to your industry, size, and specific requirements.  </li>
        <li className='li text-start'style={{color:'black', marginTop:'30px'}} >Innovation: We stay updated with the latest trends and technologies to deliver innovative solutions that drive your business forward.  </li>
        <li className='li text-start'style={{color:'black', marginTop:'30px'}} >Collaboration: We work closely with you to ensure that our solutions align with your business goals and objectives.  </li>
        <li className='li text-start' style={{color:'black', marginTop:'30px'}}>End-to-End Support: From consultation to implementation and ongoing support, we're here to guide you throughout the entire journey. </li>
      </ul>
    </div>
    <h4 className='r'>Industries We Serve</h4>
   <ul className="fa-icon-list-horizontal">
  <li>
    <FontAwesomeIcon icon={faUniversity} className="section-icon1" />
    Finance and Banking
  </li>
  <li>
    <FontAwesomeIcon icon={faHospital} className="section-icon1" />
    Healthcare
  </li>
  <li>
    <FontAwesomeIcon icon={faShoppingCart} className="section-icon1" />
    E-commerce and Retail
  </li>
  <li>
    <FontAwesomeIcon icon={faIndustry} className="section-icon1" />
    Manufacturing
  </li>
  <li>
    <FontAwesomeIcon icon={faLaptop} className="section-icon1" />
    Technology and IT Services
  </li>
  <li>
    <FontAwesomeIcon icon={faUniversity} className="section-icon11" />
    Government and Public Sector
  </li>
</ul>
<br></br>
<br></br>

<br></br>

    </>
  )
}
