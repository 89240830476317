import React from 'react'
import CaseStudyCard from './CaseStudyCard'

export default function Agricultualproduct() {
  return (
    <div>
     <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/Agriculture-bg.jpg"
              className="d-block w-100"
              style={{ height: "700px" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 black-color">
              Agricultural
              </div>

              {/* <div
                className="component-paras"
                style={{ color: "white", margin: "50px" }}
              >
                "Empower Your Digital Horizon – Seamless Cloud Integration and
                Management"
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div class="container21" style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
                fontSize: "26px",paddingBottom:'30px',gridTemplate:'none'
              }}>
          We are a family company providing agricultural products, technology, food, ingredients, agricultural solutions that are vital for living. We connect farmers with many products so they can prosper. We connect customers with ingredients so they can make meals people love. 

Today, the need to make our food system more sustainable and resilient is urgent. Sitting at the heart of the global supply chain, we have a unique ability, and responsibility, to help. Through new innovations, strategic partnerships, and 159 years of experience, we help businesses grow, and communities and people around the world flourish — today, and for generations to come.

         

          {/* right part */}
          {/* <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                class="content2img"
                src="/Images/devops.jpg"
                alt="Description of the image"
                style={{ width: "450px", height: "450px" }}
              />
            </div>
          </div> */}
        </div>
          {/* <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div> */}
      </div>
     <div className="section5">
        {/* <h1 className="section5heading">Consumer</h1> */}
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/smart-farm.jpg"
            title="Smart Farm"
            description={<ul>
               <li>IOT  </li>
            </ul>}
           
          />
          <CaseStudyCard
            imageUrl="/Images/plant.jpg"
            title="Plants"
            description={<ul>
               <li>Fruits Plants tree</li>
              
            </ul>}
            // linkUrl="/agricultural"
          />
          <CaseStudyCard
            imageUrl="/Images/seed-feeder-eqp.jpg"
            title="Equipment's"
            description={<ul>
             <li>Seed feeder accessories</li>

            </ul>}
            // linkUrl="/construction"
          />
          <CaseStudyCard
            imageUrl="/Images/robot-spray.jpg"
            title="Smart tech"
            description={<ul>
             <li>Autonomous Agricultural Robot for Weeding, Seeding, Spraying, Planting
</li>
              

            </ul>} 
            // linkUrl="/industrial"
          />
          {/* <CaseStudyCard
            imageUrl="/Images/Services.jpg"
            title="Services"
            description="" 
            linkUrl="/services"
          /> */}
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>
    
      
    </div>

  )
}
