import React from "react";

import  "./Privacy.css";
import { Link } from "react-router-dom";




export default function VM() {

    return (

        <>

       {/* <div><NavBar/></div>  */}

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">





        <section id="sml_ht_home_banner">

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 ">

                    <h1 className="h2-heading white-color l-top">

                    Privacy Statement

                    </h1>

                  </div>

            </div>

          </article>

        </section>

       

</div>
<section className="pt75" style={{textAlign:'left'}}>
    <article className="container">
      <div className="row row-margin"style={{textAlign:'left'}}>
        
         <div className="col-md-12 col-sm12 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s" style={{marginTop:"0"}}>
          <p> <em>Last updated on </em></p>
  <p>OMSK Limited, its subsidiaries and branch operations hereinafter referred as ‘Omsk’, ‘we’, ‘us’ or ‘our’ is committed to respect your privacy and choices. The privacy statement highlights our privacy practices regarding Personal Information that we collect and process depending on your association with OMSK and nature of processing activity in compliance to applicable data privacy regulations.</p>
  <h3>Objective:</h3>
  <p>Through this privacy statement we intend to provide a synopsis of our privacy practices regarding Personal Information that we collect and process about you through various sources. Although the primary focus of this statement is on the data collected and processed through our websites, that link to this Privacy Statement, which include www.Omsk.com, www.Omskbpm.com and their sub-domains such as blogs.Omsk.com, abm.Omsk.com, etc. (Collectively referred to as “OMSK Websites”), our adherence to the below mentioned principles remain across the organization towards personal data processing. Depending on the purposes of processing and your relationship with us as a data subject, we may provide additional customized privacy statements, more information on which can be found below.</p>
<br></br>
<p>The scope of this Statement covers the categories of personal data collected, how we use or process such data, who are the recipients of such data, and your associated rights under applicable laws including how to exercise the same. The applicability of the Privacy Statement is global, however certain additional information may be relevant to you depending on the country where you reside. Such additional terms, based on these particular countries or regions, are called out in region-specific statements below.</p>
<br></br>
<p>Wherever we may have provided the translation of our Statement in local languages, as per applicability, in case of a conflict between the local language and the English version, the English version shall prevail.

</p>
  <h3> Personal Information we collect and process and how we use it</h3>
  <p> <strong>- Personal Information that we process</strong></p>
  <p>For the purposes of this privacy statement, 'Personal Information' is any data which relates to an individual who may be identified from that data, or from a combination of a set of data, and other information which is in possession of Omsk.</p>
  <p>We collect the following categories of Personal Information:</p>
  <ul>
    <li>Information collected as part of your visit and usage of our websites, such as Internet Protocol (IP) address, demographics, your computers’ operating system, device information, telemetry data and browser type and information collected via cookies or similar tracking technologies. As a website visitor who merely browses our websites (including the hosted content), without submitting any additional data, this is the most relevant paragraph for applicable data processing.</li>
    <li>Personal and Contact details, such as, Name, title, e-mail address, phone numbers, address, designation, Company/Organization, Industry, Location (in terms of City/Country), LinkedIn profiles/URLs (or similar professional data set).</li>
    <li>Login credentials, such as Username and Passwords, when you register for any of our applicable services.</li>
    <li>Audio-Visual information such as photographs or images captured, video recordings (if enabled), when attending any of our events, webinars etc.</li>
    <li>Queries, comments, or feedback as submitted by you, including any correspondence you have made with us.</li>
    <li>Preferences relating to marketing communications, interest areas, subscription details, dietary preferences/allergies (in relations to events we may organize).</li>
    <li>Additionally, we may collect certain data points based on the categories of data subject and their relationship with us, as are detailed out in additional Privacy Statements for relevant categories of data subjects at the end of this Statement.</li>
  </ul>
  <p> <strong>- Use of your Personal Information</strong></p>
  <p>We use your Personal Information for the following purposes:</p>
  <ul>
    <li>to provide better usability, troubleshooting and site maintenance;</li>
    <li>to understand which parts of the website are visited and how frequently;</li>
    <li>to create your user ID;</li>
    <li>to identify you once you register on our website;</li>
    <li>to contact you and respond to your questions or requests; and </li>
    <li>to provide access to desirable content based on your preferences.</li>
    <li>to process job application and alumni related requests, more details about which are available on corresponding portals of this website.</li>
    <li>To know your interest of participation in ideathons or other competitions</li>
    <li>As a participant of the competition, to publish your name and corresponding details to make them available to larger audience on the Internet</li>
  </ul>
  <p> <strong>- Legal basis of the processing</strong></p>
  <p>The applicable privacy and data protection laws provide for certain justifiable grounds for collection and processing of personal data, commonly referred to as legal basis of processing. We primarily rely on the following legal bases:</p>
  <ul>
    <li>- We process your Personal Information when it is necessary for the performance of a contract to which you are the party or in order to take steps at your request prior to entering into a contract, e.g., when you would engage with us for receiving our services and offerings, or when managing the data of our employees for ensuring the performance of their employment contract.</li>
    <li>- We process your Personal Information when it is necessary for the purposes of a legitimate interest pursued by us or a third party (when these interests are not overridden by your data protection rights), e.g., when we need to understand your usage of our website and interaction with the same, for generating your secure login credentials, or to optimize our processes.</li>
    <li>- We process your Personal Information with your consent. Where we process Personal Data based on consent, your consent is revocable at any time, e.g., when registering and inviting you for events organized by us, or for sharing our marketing related communications with you.</li>
    <li>- We may process your personal Information to comply with any Legal obligations on us, including to applicable laws and protecting our legal rights, seeking remedies, and defending against claims.</li>
  </ul>
  
  <h3>- Consequences of not providing Personal Information</h3>
  <p>If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able to provide the corresponding service.</p>
  <h3>- Data recipients, transfer, and disclosure of Personal Information:</h3>
  <p>OMSK does not share your Personal Information with third parties for their direct marketing purposes. </p>
  <p>We share your Personal Information within </p>
  <ul>
    <li>OMSK or with any of its subsidiaries;</li>
    <li>Business partners;</li>
    <li>Service vendors;</li>
    <li>Authorized third-party agents; or </li>
    <li>Contractors.</li>
  </ul>
  <p>OMSK Limited does not sell your Personal Information to third parties.</p>
  <p>In general, OMSK Limited, domiciled in Bangalore, is the data controller processing your Personal Information. The following applies only where the data controller processing your Personal Information is domiciled in the European Economic Area (“EEA”):</p>
  <p>We transfer Personal Information to countries outside the EEA to third parties, including to countries which have different data protection standards to those which apply in the EEA. The locations of OMSK group companies is set out <a href="contact.html" title="here">here</a>. Our service providers are primarily located in USA, Canada, Australia, Singapore, Hong Kong, India and UK. Where service providers process your Personal Information in countries deemed adequate by the European Commission, we rely on the European Commission's decision to protect your Personal Information.</p>
  <p>For transfers to OMSK group companies and service providers outside the EEA, we use standard contractual clauses or rely on a service provider's (EU data protection authority approved) corporate rules that are in place to protect your Personal Information. Please contact us as set out below if you would like to obtain a copy of the methods used. When required, OMSK discloses Personal Information to external law enforcement bodies or regulatory authorities, in order to comply with legal obligations.</p>
  <a name="cookiesstatement" id="cookiesstatement"></a>
  <h3>- Use of cookies:</h3>
  <p>Please refer the <a href="privacy-statement/cookie-policy.html" title="Cookie Policy">Cookie Policy</a></p>
  <h3>-Do Not Track</h3>
  <p>Do Not Track (DNT) is a privacy preference that users can set in some web browsers, allowing users to opt out of tracking by websites and online services. At the present time, the World Wide Web Consortium (W3C) has not yet established universal standards for recognizable DNT signals and therefore, OMSK Limited and the Site do not recognize DNT.</p>
  <h3>- Your Rights:</h3>
  <p>Subject to the laws of your country, You have the right to access, correct, delete or transfer your Personal Information that we hold, including your profile and preferences. You also have the right to object to certain processing and, where we have asked for your consent to process your Personal Information, to withdraw this consent. Where we process your Personal Information because we have a legitimate interest in doing so (as explained above), you also have a right to object to this. These rights may be limited in some situations – for example, where we can demonstrate that we have a legal requirement to process your Personal Information.</p>
  <p>If you are Brazilian user, you have additional rights to take confirmation on existence of your personal information, portability of your personal information to another service provider if applicable, anonymization or blocking of unnecessary information, information about public and private entities with which we have shared your data.</p>
  <p>You can assert your rights in the corresponding sections where such information was provided or by contacting us at <a href="mailto:privacy@Omsk.com" title="privacy@Omsk.com">privacy@Omsk.com</a></p>
  <h3>- Your California Privacy Rights</h3>
  <p>California residents who provide personal information in obtaining products or services for personal, family, or household use are entitled to request and obtain from us once a calendar year information about the customer information we shared with third parties for their own direct marketing purposes, including the categories of information and the names and addresses of those businesses with which we have shared such information.  As discussed elsewhere in this Privacy Policy, we do not currently share the personal information of California residents with third parties for their own direct marketing purposes.  However, if you have further questions about our privacy practices and compliance with California law, please contact us as explained below.</p>
  <h3>- Data security</h3>
  <p>OMSK adopts reasonable and appropriate security practices and procedures including administrative, physical security, and technical controls in order to safeguard your Personal Information.</p>
  <h3>- Data retention</h3>
  <p>Personal Information will not be retained for a period more than necessary to fulfill the purposes outlined in this privacy statement, unless a longer retention period is required by law or for directly related legitimate business purposes.</p>
  <h3>- Linked websites</h3>
  <p>Our privacy practices regarding Personal Information that we collect and store about you through our portals such as Recruitment and Global Alumni will be as per the privacy policy of those portals.</p>
  <p>OMSK provides links to third-party websites and services. However, OMSK is not responsible for the privacy statements, practices, or the contents of such third-party websites.</p>
  {/* <h3>- Children’s Privacy</h3>
  <p>The Site does not knowingly solicit or collect personally identifiable information online from children under the age of 13 without prior verifiable parental consent. If OMSK Limited learns that a child under the age of 13 has submitted personally identifiable information online without parental consent, it will take all reasonable measures to delete such information from its databases and to not use such information for any purpose (except where necessary to protect the safety of the child or others as required or allowed by law). If you become aware of any personally identifiable information we have collected from children under 13, please call us at +91 80 2852 0261 or email us at <a href="mailto:privacy@Omsk.com" title="privacy@Omsk.com">privacy@Omsk.com</a>.</p>
  <p>Minors under 18 years of age may have the personal information that they provide to us deleted by sending an email to <a href="mailto:privacy@Omsk.com" title="privacy@Omsk.com">privacy@Omsk.com</a> requesting deletion.  Please note that, while we make reasonable efforts to comply with such requests, deletion of your personal information does not ensure complete and comprehensive removal of that data from all systems.</p>
  <h3>- How to contact us</h3>
  <p>If you have any questions regarding our privacy practices or this privacy statement, or to request this privacy statement in another format, please contact us at:<br></br>
    Contact person: <br></br>
    Contact address: OMSK CO,. LTD <br></br>
    Phone: <br></br>
    Email: <Link to ="mailto:privacy@Omsk.com" title="privacy@Omsk.com">privacy@Omsk.com</Link></p> */}
  <h3>- Updates to this privacy statement</h3>
  <p>OMSK may change the data privacy practices and update this privacy statement as and when the need arises, and the same will be made available on the website. But our commitment to protect the privacy of website users will continue to remain.</p>
  <a name="data"></a>
  {/* <p>For Australia Privacy Policy, please click <a title="here" href="privacy-statement/australian-privacy-policy.html">here</a>.</p> */}
  {/* <p>For Brazil website visitors, Privacy policy in Portuguese available <a title="here" href="privacy-statement/privacy-statement-portuguese.pdf" target="_blank">here</a>.</p> */}



            
        </div>
        
        </div>
    
    </article>
  </section>
  
        

        

        </>
        

    )

  }
