import { useEffect, useRef, useState } from "react";

import "./Home.css";

import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

import AOS from "aos";
import CaseStudyCard from "./CaseStudyCard";

// import "./font.css";

const Home = () => {
  const [isWhiteBackground, setIsWhiteBackground] = useState("");

  const [activeSection, setActiveSection] = useState(null);

  // const [showText, setShowText] = useState(false);

  const [currentDiv, setCurrentDiv] = useState("");

  const Hexagon = ({ text, url }) => (
    <Link to={url} className="hexagon-link">
      <div className="hexagon">
        {/* <img src={imageUrl}  /> */}

        <p className="hex-text">{text}</p>
      </div>
    </Link>
  );

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const honeycombData = [
    // Row 1 (6 hexagons)

    [
      { url: "/service/digital-marketing", text: "Digital Marketing" },

      {
        text: "Social Media Development and Management",

        url: "/service/SocialMediaDevelopment",
      },

      {
        text: "Technical Document / Content Writing",

        url: "/service/technical-documents-or-content",
      },

      { text: "Website Development", url: "/service/WebsiteDevelopment" },

      { text: "GIS App Development", url: "/service/gis-app" },

      { text: "Mobile App Development", url: "/service/MobileApplication" },
    ],

    // Row 2 (4 hexagons)

    [
      { text: "Gaming Development", url: "/service/Gaming" },

      { text: "Blockchain", url: "/service/blockchain" },

      { text: "Advanced Toll Survey", url: "/service/advanced-toll-survey" },

      { text: "Internet of Things", url: "/service/IoT" },

      { text: "Business Intelligence", url: "/service/BusinessIntelligence" },
    ],

    // Row 3 (6 hexagons)

    [
      { text: "ERP/RPA Implementation", url: "/service/erp-rpa-implementaion" },

      { text: "SAAS Development support", url: "/service/SaasDevelopment" },

      { text: "Reporting and Analytics", url: "/services/reporting" },

      { text: "Banking planforms", url: "/service/BankingPlatform" },

      { text: "VOIP Platforms", url: "/service/VOIP" },

      { text: "E commerce platform", url: "/service/Ecommerce" },
    ],

    // Row 4 (4 hexagons)

    [
      { text: "Website Testing", url: "/service/WebsiteTesting" },

      { text: "Mobile App Testing", url: "/service/MobileApplication" },

      { text: "Cyber Security", url: "/service/CyberSecurity" },

      {
        text: "Business Process Management",

        url: "/services/business-managment-process",
      },

      { text: "Staffing and consulting Services", url: "/service/Staffing" },
    ],
  ];

  const divRefs = useRef([]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;

    const scrollTop = window.scrollY;

    const divHeight = windowHeight;

    const currentDivIndex = Math.floor(scrollTop / divHeight);

    const currentDivId = `item-${currentDivIndex + 1}`;

    const currentDiv = document.getElementById(currentDivId);

    currentDiv.style.backgroundColor === ""
      ? setIsWhiteBackground("black")
      : setIsWhiteBackground("white");

    const navLinks = document.querySelectorAll(".nav-link span");

    const navLines = document.querySelectorAll(".nav-link .line");

    // const sideNav = document.querySelectorAll('.nav-link');

    const sideNav = document.querySelectorAll(".nav-link");

    for (let i = 0; i < navLinks.length; i++) {
      navLinks[i].style.color =
        currentDiv.style.backgroundColor === "white"
          ? "black"
          : "white";
    }

    for (let j = 0; j < navLines.length; j++) {
      //  navLinks[i].style.color = currentDiv.style.backgroundColor === "" || currentDiv.style.backgroundImage === ""  || currentDiv.style.backgroundColor === "white" ? "white" : "black";

      // navLines[j].style.backgroundColor = currentDiv.style.backgroundColor === "" || currentDiv.style.backgroundColor === "white" || currentDiv.style.backgroundColor === "rgb(150, 53, 150)" || currentDiv.style.backgroundColor ===  "rgb(255, 255, 255)"   ? "black" : "white";

      navLines[j].style.backgroundColor =
        currentDiv.style.backgroundColor === "white"
          ? "black"
          : "white";
    }

    console.log(navLines);

    setActiveSection(currentDivId);

    setCurrentDiv(currentDivId);

    // scrollToSection(item-${currentDivIndex + 1})
  };

  
  

  const scrollToSection = (id) => {
    const section = document.getElementById(id);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Home = () => {
    // Extract the sectionId from the URL parameters
    const { sectionId } = useParams();

    // Scroll to the section with the specified id when the component mounts
    useEffect(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, [sectionId]);
  };

  return (
    <div className="main-div">
      <div className="desktop-nav">
        <nav
          style={{
            zIndex: 10,

            top: 0,

            bottom: 0,

            margin: "auto",

            color: isWhiteBackground,
          }}
          className="h-50  position-fixed align-middle pe-4 "
        >
          <li
            onClick={() => scrollToSection("item-1")}
            className={`nav-link p-3 ${
              activeSection === "item-1" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">
              Navigate <br></br> Your Next
            </span>
          </li>

          <li
            onClick={() => scrollToSection("item-2")}
            className={`nav-link p-3 ${
              activeSection === "item-2" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">Vission</span>
          </li>

          <li
            onClick={() => scrollToSection("item-3")}
            className={`nav-link p-3 ${
              activeSection === "item-3" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">Mission</span>
          </li>

          {/* <li
            onClick={() => scrollToSection("item-4")}
            className={`nav-link p-3 ${
              activeSection === "item-4" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">How</span>
          </li> */}

          <li
            onClick={() => scrollToSection("item-5")}
            className={`nav-link p-3 ${
              activeSection === "item-5" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">Products</span>
          </li>

          <li
            onClick={() => scrollToSection("item-6")}
            className={`nav-link p-3 ${
              activeSection === "item-6" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">Services</span>
          </li>

          {/* <li
            onClick={() => scrollToSection("item-7")}
            className={`nav-link p-3 ${
              activeSection === "item-7" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">
              Bharatmake <br /> Initiative
            </span>
          </li> */}

          <li
            onClick={() => scrollToSection("item-8")}
            className={`nav-link p-3 ${
              activeSection === "item-8" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">Client List</span>
          </li>

          <li
            onClick={() => scrollToSection("item-9")}
            className={`nav-link p-3 ${
              activeSection === "item-9" ? "active" : ""
            }`}
          >
            <div className="line"></div>

            <span className="newfontchange">Contact Us</span>
          </li>
        </nav>
      </div>

      {/* {showText && <p className="scroll-text">Scrolling text</p>} */}

      <div div className="image-container" id="main-div">
        <div
          ref={(el) => (divRefs.current[0] = el)}
          id="items-1"
          className="screen col-md-12"
          style={{backgroundColor: "#DF9926"}}
          
        >
        {/* <video autoPlay loop muted  className="video-bg">
            <source src="/Images/12336960-hd_1920_1028_60fps.mp4" type="video/mp4"/>
        </video> */}
          <div
            id="item-1"
            data-aos="slide-down"
            data-aos-duration="700"
            className="container w-60 float-start "

          >
          <div id="heading" className="heading" style={{ marginTop: "20px" }}>
          WELCOME TO OMSK CO.,LTD
          {/* <img src={"/Images/omsk-home-title.png"} alt="logo" className="logo-image" style={{width:'700px',height:'700px'}} /> */}
              
            </div>
         
            {/* <h1 id="heading" className="heading" style={{ marginTop: "130px" }}>
              Champions Of Innovation
            </h1>
            

            <div className=" mt-3">
              <Link to="/about-us" className=" atul-button " target="_self">
                <div className="atul-text text-uppercase">About Us</div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <div
        ref={(el) => (divRefs.current[1] = el)}
        id="item-2"
        className="screen col-md-12"
        style={{
          position: "relative",

          backgroundColor: "white",

          // backgroundImage:

          //   'url("https://images.unsplash.com/photo-1527380992061-b126c88cbb41?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1965&q=80")',

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
        }}
      >
        <div
          id="item-2"
          className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "
        >
          <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
            <section
              id="core_capabilities"
              className="scroll-section relative bg-topaz-medium home_promo_banner"
            >
              <article className="container">
                <div className="row">
                <h2 className="home-second-heading black-color  mb-xs-10"
                        data-aos="fade-in"
                        data-wow-delay="0.2s">Vission</h2>
                <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          style={{ paddingLeft: "0",paddingTop:'30px' }}
                        >
                          <h3
                            className="fullpage-second-desc  black-color"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                           To be the leading global force in trade, fostering economic prosperity and sustainable growth through innovative solutions, ethical practices, and unwavering commitment to excellence.

                          </h3>
                        </div>
                  {/* <div
                    className="content home-second-heading-top wow fadeInUp"
                    data-aos="fade-in"
                    data-wow-delay="0.2s"
                  >
                    <div
                      className="col-lg-6 col-m d-6 col-sm-6 col-xs-12"
                      data-aos="fade-in"
                      data-wow-delay="0.2s"
                    >
                      <div id="why">
                        <h2
                          className="home-second-heading white-color  mb-xs-10"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                        >
                          Vission
                        </h2>

                        <p
                          className="fullpage-desc white-color"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                        >
                          <em>
                            TO BRING TOGETHER THE BEST TECHNOLOGY AND OUR PEOPLE
                            TO SUPERCHARGE PROGRESS.
                          </em>
                        </p>

                       

                        <div
                          className="row hidden-xs"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                        >
                          <div
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                            style={{ paddingLeft: "0" }}
                          >
                            <h3
                              className="fullpage-second-desc white-color"
                              data-aos="fade-in"
                              data-wow-delay="0.2s"
                            >
                              We look forward to supercharging the next phase of
                              our growth with optimism and confidence, ready to
                              seize the many Opportunities ahead. Our growth
                              strategy for the emerging digital future is built
                              on five strategic objectives.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div
                  className="background fullCoverImg ai-powered-core-img dnf-digital-capabilities-lead"
                  data-aos="fade-in"
                  data-wow-delay="0.2s"
                  id="whyimg"
                  style={{
                    backgroundImage:
                      'url("/Images/digital-capabilities-lead-tab.png")',
                  }}
                ></div> */}
              </article>
            </section>
          </div>
        </div>
      </div>

      <div
        ref={(el) => (divRefs.current[2] = el)}
        id="item-3"
        className="screen col-md-12"
        style={{
          backgroundAttachment: "fixed",

          // backgroundImage:

          //   'url("https://img.freepik.com/premium-vector/cave-vector-cartoon-background_100478-544.jpg?size=626&ext=jpg&ga=GA1.1.714599829.1686029259&semt=ais")',

          backgroundColor: "white",

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",

          backgroundPosition: "center",
        }}
      >
        <div
          id="item-3"
          className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "
        >
          <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
            <section
              id="core_capabilities"
              className="scroll-section relative bg-topaz-medium home_promo_banner"
            ></section>

            {/* <div
              className="background fullCoverImg-left agile-digital-bg-img dnf-digital-operating-lead"
              data-aos="fade-in"
              data-wow-delay="0.2s"
              style={{
                backgroundImage:
                  'url("/Images/digital-operating-models-lead.png")',
              }}
            ></div> */}

            <article className="container">
              <div className="row ">
              <h2
                        className="home-second-heading black-color  mb-xs-10"
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                      >
                        Mission
                      </h2>
                      <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          style={{ paddingLeft: "0",paddingTop:'30px' }}
                        >
                          <h3
                            className="fullpage-second-desc black-color"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                           Our mission is to provide our customers with the right expertise and solutions and to enable our partners to grow and expand their business in the region.

                          </h3>
                        </div>
                {/* <div
                  className="content home-third-heading-top "
                  data-aos="fade-in"
                  data-wow-delay="0.2s"
                >
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-md-offset-6 col-xs-12  "
                    data-wow-delay="0.3s"
                    style={{ float: "right" }}
                  >
                    <div id="what">
                      <h2
                        className="home-second-heading white-color  mb-xs-10"
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                      >
                        What
                      </h2>

                      <p
                        className="fullpage-desc white-color"
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                      >
                        <em>
                          WE BUILD GREATER FUTURES THROUGH INNOVATION AND
                          COLLECTIVE KNOWLEDGE.
                        </em>
                      </p>

                      <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird"></p>

                      <div
                        className="row hidden-xs"
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                      >
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          style={{ paddingLeft: "0" }}
                        >
                          <h3
                            className="fullpage-second-desc white-color"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                            OMSK CO,. LTD is an It services, consulting and
                            business solutions organization that has been
                            partnering with many of the world's largest
                            businesses in their transformation journeys for over
                            50 years.
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </article>

            {/* </section> */}
          </div>
        </div>
      </div>

      {/* <div
        ref={(el) => (divRefs.current[3] = el)}
        id="item-4"
        className="screen col-md-12"
        style={{
          position: "relative",

        
          backgroundColor: "#963596",

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
        }}
      >
        <div
          id="item-4"
          className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "
        >
          
          <div
            className="freeflowhtml aem-GridColumn aem-GridColumn--default--12"
            data-aos="fade-in"
            data-wow-delay="0.2s"
          >
            <section
              id="talent_transformations"
              className="scroll-section relative bg-amethyst-medium home_promo_banner"
              data-aos="fade-in"
              data-wow-delay="0.2s"
            >
              <article
                className="container"
                data-aos="fade-in"
                data-wow-delay="0.2s"
              >
                <div className="row" data-aos="fade-in" data-wow-delay="0.2s">
                  <div
                    className="content home-second-heading-top"
                    data-aos="fade-in"
                    data-wow-delay="0.2s"
                  >
                    <div
                      className="col-lg-6 col-md-6 col-sm-6 col-xs-12 wow fadeInUp animateFirst"
                      data-aos="fade-in"
                      data-wow-delay="0.2s"
                    >
                      <div id="How" data-aos="fade-in" data-wow-delay="0.2s">
                        <h2
                          className="home-second-heading white-color mb20 mb-xs-10"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          style={{ marginBlockEnd: "0" }}
                        >
                          How
                        </h2>

                        <p
                          className="fullpage-desc white-color"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                        >
                          <em>
                            OMSK TRANSFORMS BUSINESSES THROUGH TECHNOLOGY.
                          </em>
                        </p>

                        <p className="mb20 mb-xs-10 mt50 mt-xs-0 animateThird">
                        </p>

                        <div
                          className="row hidden-xs"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                        >
                          <div
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-xs-20"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                            style={{ paddingLeft: "0" }}
                          >
                            <h3
                              className="fullpage-second-desc white-color"
                              data-aos="fade-in"
                              data-wow-delay="0.2s"
                            >
                              Work-Life balance professional development
                              opportunities, Inclusive and diverse workplaces
                              collaboration and recognition by recognizing these
                              aspects and providing the necessary support and
                              opportunities.
                            </h3>

                            <p className="fullpage-second-more text-uppercase black-color mt20">
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="background fullCoverImg learn-bg-img dnf-talent-transformations-lead"
                  data-aos="fade-in"
                  data-wow-delay="0.2s"
                  style={{
                    backgroundImage:
                      'url("/Images/talent-transformations-lead.png")',
                  }}
                ></div>
              </article>
            </section>
          </div>
        </div>
      </div> */}

      <div
        id="item-5"
        className="screen col-md-12 "
        style={{
          backgroundAttachment: "fixed",

          // backgroundImage:

          //   'url("https://images.unsplash.com/photo-1528459584353-5297db1a9c01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1199&q=80")',

          backgroundColor: "white",

          backgroundRepeat: "no-repeat",

          backgroundSize: "cover",
        }}
      >
        <div
          id="item-5"
          className="screen col-md-12 "
          style={{
            backgroundAttachment: "fixed",

            // backgroundImage:

            //   'url("https://images.unsplash.com/photo-1528459584353-5297db1a9c01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1199&q=80")',

            backgroundColor: "white",

            backgroundRepeat: "no-repeat",

            backgroundSize: "cover",
          }}
        >
           <div >
      
      <div className="section5">
        <h1 className="section5heading">Products</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/shopping.png"
            title="Consumer"
            description=""
            // to="/consumer"
            linkUrl="/consumer"
          />
          <CaseStudyCard
            imageUrl="/Images/Agriculture.jpg"
            title="Agricultural"
            description=""
            linkUrl="/agricultural"
          />
          <CaseStudyCard
            imageUrl="/Images/Construction.jpg"
            title="Construction"
            description=""
            linkUrl="/construction"
          />
          <CaseStudyCard
            imageUrl="/Images/Industrial.jpg"
            title="Industrial"
            description="" 
            linkUrl="/industrial"
          />
          <CaseStudyCard
            imageUrl="/Images/Services.jpg"
            title="Services"
            description="" 
            linkUrl="/services"
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>
      </div>

      {/* <div id="Productchat">
        <div className="w-100 d-flex justify-content-center">
          <div className="container p-0 w-100">
            <div className="row">
              <div id="productimg" className="col-md-6 p-0">
                <div id="productimg1" className="p-0 hover-zoom">
                  <Link to="/consumer">
                    <figure data-aos="fade-in" data-wow-delay="0.2s">
                      <img
                        className=""
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                        src="/Images/shopping.png"
                        alt="Experience"
                      />
                      <figcaption>
                        <h1 className="title" data-aos="fade-in" data-wow-delay="0.2s">
                          Consumer
                        </h1>
                      </figcaption>
                    </figure>
                  </Link>
                </div>
              </div>

              <div className="col-md-6 p-0" id="okedit101">
                <div className="row col-md-12 p-0">
                  <div id="figcaption-img" className="col col-md-6 p-0">
                    <Link to="/agricultural">
                      <figure className="fig-small">
                        <img
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          src="/Images/Agriculture.jpg"
                          alt="Online Tutor Systems"
                        />
                        <figcaption id="small-figcaption">
                          <h6 className="" data-aos="fade-in" data-wow-delay="0.2s">
                           Agricultural
                          </h6>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>

                  <div className="col col-md-6 p-0">
                    <Link to="/construction">
                      <figure className="fig-small">
                        <img
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          src="/Images/Construction.jpg"
                          alt="GPS"
                        />
                        <figcaption id="small-figcaption">
                          <h6 className="" data-aos="fade-in" data-wow-delay="0.2s">
                           Construction
                          </h6>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>
                </div>

                <div className="row col-md-12 p-0">
                  <div className="col col-md-6 p-0">
                    <Link to="/industrial">
                      <figure className="fig-small">
                        <img
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          src="/Images/Industrial.jpg"
                          alt="Advanced Toll Survey"
                          style={{paddingTop:"46px"}}

                        />
                        <figcaption id="small-figcaption">
                          <h6 className="" data-aos="fade-in" data-wow-delay="0.2s">
                            Industrial
                          </h6>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>

                  <div className="col col-md-6 p-0">
                    <Link to="/product/Project">
                      <figure className="fig-small">
                        <img
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                          src="/Images/Services.jpg"
                          alt="Project Management"
                          style={{paddingTop:"46px"}}
                        />
                        <figcaption id="small-figcaption">
                          <h6 className="" data-aos="fade-in" data-wow-delay="0.2s">
                           Services
                          </h6>
                        </figcaption>
                      </figure>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
        </div>

        <div
          id="item-6"
          className="screen col-md-12 "
          style={
            {
              //    backgroundImage:
              //     'url("https://media.istockphoto.com/id/1393772769/photo/mvp-minimum-viable-product-concept-for-lean-startup-life-cycle-of-product-development.webp?b=1&s=170667a&w=0&k=20&c=upLis9vnUCTKklUwqgazOmPXdyXU_Lah5cCA3T5SC8g=")'
            }
          }
        >
          {/* <div id="item-6" className="col-md-9 float-center pt75 ">
 
            <h1 id="productserviceheading" className=" pq heading text-center">Services</h1>
 
            <div className="w-100 d-flex justify-content-center">
 
              <div className="container p-0 w-100"style={{ border: '1px solid black',borderRadius: '50%',  backgroundColor: 'red' }}  >
 
                <div className="row" >
 
                  <div className="col-md-6 p-0"  style={{
 
                    background: "#DF9926",border: '1px solid black',borderRadius: '50%'
 
                  }}>
 
                    <div  className="   p-0 hover-zoom" >
 
                     
 
                    </div>
 
                  </div>
 
                  <div className="col-md-6 p-0">
 
                    <div className="row col-md-12 p-0" >
 
                      <div id="figcaption-img" className="col col-md-6 p-0" style={{ border: '1px solid black',borderRadius: '50%' }}>
 
                        <Link to="/">
 
                         
 
                        <img src="/Images/quarter-results-q4fy21-thumb.jpg"></img>
 
                           
 
                         
 
                        </Link>
 
                      </div>
 
                      <div className="col col-md-6 p-0" style={{ border: '1px solid black',borderRadius: '50%' }}>
 
                        <Link to="/">
 
                         
 
                        <img src="/Images/future-work-report.jpg"></img>
 
                           
 
                        </Link>
 
                      </div>
 
                    </div>
 
 
 
                    <div className="row col-md-12 p-0">
 
                      <div className="col col-md-6 p-0" style={{ border: '1px solid black',borderRadius: '50%' }}>
 
                        <Link to="/">
 
                        <img src="/Images/esg-report-2021-home.jpg"></img>
 
                        </Link>
 
                      </div>
 
                      <div className="col col-md-6 p-0" style={{ border: '1px solid black',borderRadius: '50%' }}>
 
                        <Link to="/">
 
                        <img src="/Images/cloud-applications-research.jpg"></img>
 
 
 
                        </Link>
 
                      </div>
 
                    </div>
 
                  </div>
 
                </div>
 
              </div>
 
            </div>
 
          </div> */}

          {/* <div className="honeycomb-container">
 
            <h1 id="productserviceheading" className=" pq heading text-center">
 
              Services
 
            </h1>
 
            {rows.map((row, rowIndex) => (
 
              <div key={row-${rowIndex}} className="honeycomb-row">
 
                {row.hexagons.map((hexagon, colIndex) => (
 
                  <Hexagon
 
                    key={hexagon-${rowIndex}-${colIndex}}
 
                    imageUrl={hexagon.image}
 
                    text={hexagon.text}
 
                  />
 
                ))}
 
              </div>
 
            ))}
 
          </div> */}

          <div
            className="honeycomb-container "
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            <h1
              id="productserviceheading"
              className=" pq heading text-center"
              data-aos="fade-in"
              data-wow-delay="0.2s"
            >
              Services
            </h1>

            {honeycombData.map((row, rowIndex) => (
              <div
                key={`row-${rowIndex}`}
                className={`honeycomb-row ${
                  rowIndex % 2 === 0 ? "even-row" : "odd-row"
                }`}
                data-aos="fade-in"
                data-wow-delay="0.2s"
              >
                {row.map((hexagon, colIndex) => (
                  <Hexagon
                    key={`hexagon-${rowIndex}-${colIndex}`}
                    imageUrl={hexagon.image}
                    text={hexagon.text}
                    url={hexagon.url}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>

       

        <div
          id="item-8"
          className="screen col-md-12"
          style={{
            // backgroundImage:

            // 'url("https://images.unsplash.com/photo-1528459584353-5297db1a9c01?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1199&q=80")',

            backgroundColor: "white",
          }}
        >
          <div
            id="item-8"
            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "
          >
            <h1
              id="ourclient"
              className=" pq heading text-center"
              data-aos="fade-in"
              data-wow-delay="0.2s"
            >
              Our Clients
            </h1>

            <div className="w-100 d-flex justify-content-center">
              <div id="item-8-box" className="container p-0 w-100">
                <div className="row no-gutters clients-wrap clearfix wow fadeInUp">
                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                  
                  >
                    <div
                      className="client-logo"
                    
                    >
                      <img
                        src="/Images/client_list_img/Fujitsu-logo.png"
                        className="img-fluid"
                       
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                  
                  >
                    <div
                      className="client-logo"
                     
                    >
                      <img
                        src="/Images/client_list_img/persistent logo.jpg"
                        className="img-fluid"
                       
                        alt=""
                        style={{ maxHeight: "125%", maxWidth: "120%" }}
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                  
                  >
                    <div
                      className="client-logo"
                      
                    >
                      <img
                        src="/Images/client_list_img/tata.png"
                        className="img-fluid"
                       
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                  
                  >
                    <div
                      className="client-logo"
                      
                    >
                      <img
                        src="/Images/client_list_img/newt_global.png"
                        className="img-fluid"
                       
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                    
                  >
                    <div
                      className="client-logo"
                      
                    >
                      <img
                        src="/Images/client_list_img/Goalogo.jpg"
                        className="img-fluid"
                       
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                    
                  >
                    <div
                      className="client-logo"
                      
                    >
                      <img
                        src="/Images/alois2.jpg"
                        className="img-fluid"
                      
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                    
                  >
                    <div
                      id="ouicksupplierlogo"
                      className="client-logo"
                      
                    >
                      <img
                        src="/Images/client_list_img/QS LOGO.png"
                        className="img-fluid"
                       
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                    
                  >
                    <div
                      className="client-logo"
                     
                    >
                      <img
                        src="/Images/sourceone.png"
                        className="img-fluid"
                        
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                   
                  >
                    <div
                      className="client-logo"
                     
                    >
                      <img
                        src="/Images/client_list_img/alphanso-mango.jpg"
                        className="img-fluid"
                       
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                    
                  >
                    <div
                      className="client-logo"
                     
                    >
                      <img
                        src="/Images/bintechlogo.png"
                        className="img-fluid"
                        
                        alt=""
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-4 col-xs-6">
 
                    <div className="client-logo">
 
                      <img
 
                        src="/Images/client_list_img/Ies.png"
 
                        className="img-fluid"
 
                        alt=""
 
                      />
 
                    </div>
 
                  </div> */}

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                    
                  >
                    <div
                      className="client-logo"
                     
                    >
                      <img
                        src="/Images/vvdn.png"
                        className="img-fluid"
                        
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    className="col-lg-3 col-md-4 col-xs-6"
                   
                  >
                    <div
                      className="client-logo"
                     
                    >
                      <img
                        src="/Images/client_list_img/Softcon-Logo_small-1.png"
                        className="img-fluid"
                        
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="item-9"
          className="screen col-md-12"
          style={{
            backgroundColor: "#933593",
          }}
        >
          <div
            id="item-5"
            className="col-md-9 float-center pt-3 pb-3 d-flex justify-content-center flex-wrap "
          >
            <div className="w-100 d-flex justify-content-center">
              <div className="contactus aem-GridColumn aem-GridColumn--default--12">
                <section id="contact_us" className="scroll-section">
                  <article className="container">
                    <div className="row">
                      <div
                        className="col-md-6"
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                      >
                        <div
                          className="square-grid"
                          id="okedit102"
                          data-aos="fade-in"
                          data-wow-delay="0.2s"
                        >
                          <div
                            className="square"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                            India
                          </div>

                          <div
                            className="square"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                            Thailand
                          </div>

                          <div
                            className="square"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                            Middle East
                          </div>

                          <div
                            className="square"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                            USA
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-6"
                        data-aos="fade-in"
                        data-wow-delay="0.2s"
                      >
                        <div
                          className="column-content"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <p
                            className="contact-text"
                            data-aos="fade-in"
                            data-wow-delay="0.2s"
                          >
                            Let's Help You Navigate Your Next
                          </p>

                          <Link to="/Contact" className="atul-button">
                            <div className="atul-text text-uppercase">
                              Contact us
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
