import React from 'react'
import CaseStudyCard from './components/CaseStudyCard'

export default function Industialproduct() {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide">
       <div className="carousel-inner">
         <div className="carousel-item active">
           <img
             src="/Images/industrial-bg.jpg"
             className="d-block w-100"
             style={{ height: "700px" }}
             alt="slider first"
           />
           <div className="carousel-caption  d-md-block">
             <div className="component-1 black-color">
             Industrial
             </div>

             {/* <div
               className="component-paras"
               style={{ color: "white", margin: "50px" }}
             >
               "Empower Your Digital Horizon – Seamless Cloud Integration and
               Management"
             </div> */}
           </div>
         </div>
       </div>
     </div>
     <div className="container-np2">
       {/* <!-- section2 title --> */}
       <div className="cont2-titles">
         {/* <div class="cont2-titletext">What is Cyber Security </div> */}
       </div>

       {/* <div class="container21" style={{
               

               fontFamily: "system-ui",
               fontSize: "26px",paddingBottom:'30px',gridTemplate:'none'
             }}>
         We are a family company providing agricultural products, technology, food, ingredients, agricultural solutions that are vital for living. We connect farmers with many products so they can prosper. We connect customers with ingredients so they can make meals people love. 

Today, the need to make our food system more sustainable and resilient is urgent. Sitting at the heart of the global supply chain, we have a unique ability, and responsibility, to help. Through new innovations, strategic partnerships, and 159 years of experience, we help businesses grow, and communities and people around the world flourish — today, and for generations to come.

        

         
       </div> */}
         {/* <div class="readmore">
           <ReadModal2 buttonText="Read More" modalContent={modalContent} />
         </div> */}
     </div>
    <div className="section5">
       {/* <h1 className="section5heading">Consumer</h1> */}
       <div className="section5jsxcard">
         <CaseStudyCard
           imageUrl="/Images/safety-eqp.jpg"
           title="Safety Equipment's"
          //  description={<ul>
          //     <li>Safety Net</li>
          //     <li>Safety Equipment's</li>
          //  </ul>}
          
         />
         <CaseStudyCard
           imageUrl="/Images/installation.jpg"
           title="Service"
           description={<ul>
              <li>Quality control
</li>
<li>Material handling</li>
<li>Installation</li>
             
           </ul>}
           // linkUrl="/agricultural"
         />
          <CaseStudyCard
           imageUrl="/Images/consumer-parts.jpg"
           title="Consumer parts       "
           description={<ul>
              <li>DI water
</li>
<li>Card board</li>
<li>Machining</li>
<li>Mass production jobs</li>
             
           </ul>}
           // linkUrl="/agricultural"
         />
         
         
             

          
         {/* <CaseStudyCard
           imageUrl="/Images/Services.jpg"
           title="Services"
           description="" 
           linkUrl="/services"
         /> */}

         {/* <div class="readmore">
<button class="read">show More</button>
</div> */}

         {/* section 6 */}
       </div>
     </div>
   
     
   </div>
  )
}
