import React from 'react'
import "./About.css";
import { Link } from 'react-router-dom';

export default function Contact() {
  return (
<div>
<div id="carouselExampleCaptions" className="carousel slide">
{/* <div className="carousel-indicators">
  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>

</div> */}
<div className="carousel-inner">
  <div className="carousel-item active">
    <img src="https://onemg.com/assets/images/About-Banner.jpg" className="d-block w-100" alt="slider first"/>
    {/* <div className="carousel-caption d-none d-md-block">
      <h5>First slide label</h5>
      <p>Some representative placeholder content for the first slide.</p>
    </div> */}
  </div>
  
  {/* </div><div className="carousel-item">
    <img src="https://thumbs.dreamstime.com/b/right-left-choosing-direction-right-left-choosing-direction-concept-114395400.jpg" className="d-block w-100" alt="slider second"/>
    <div className="carousel-caption d-none d-md-block">
      <h5>Second slide label</h5>
      <p>Some representative placeholder content for the second slide.</p>
    </div */}
  {/* <div className="carousel-item">
    <img src="https://thumbs.dreamstime.com/b/businessman-drawing-virtual-screen-css-concept-89391760.jpg" className="d-block w-100" alt="slider third"/>
    <div className="carousel-caption d-none d-md-block">
      <h5>Third slide label</h5>
      <p>Some representative placeholder content for the third slide.</p>
    </div>
  </div> */}
  {/* <div className="carousel-item">
    <img src="https://thumbs.dreamstime.com/b/right-left-choosing-direction-right-left-choosing-direction-concept-114395400.jpg" className="d-block w-100" alt="slider second"/>
    <div className="carousel-caption d-none d-md-block">
      <h5>Second slide label</h5>
      <p>Some representative placeholder content for the second slide.</p>
    </div>
  </div> */}
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="visually-hidden">Next</span>
</button>

</div>


<div className='about-containerss' style={{paddingLeft:'-10%'}}>
<div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

     <section id="overview" className="pb0-overview"/>

  <article className="container">

    <div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <h2 className="title-sml">Vision</h2>

      </div>

    </div>

  </article>
  </div>


  <section className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
        <div className="cmp-text">
          <div className="row">
           
          <div id="main-text-body"><article className="container"><div className="row"><div className="col-md-10 col-sm-12 col-xs-12 mb-sm-20 wow fadeInUp animated" data-wow-delay="0.3s" >
             
          <p className='tushar'>Our Vision is to be a leading Web Solution company in IT sector and progress in our current position in market. </p>

<p>We know that Customer’s growth is our growth, so we commit our customers to help in achieving their business goals.</p>

<p> We believe in work with the accuracy and best quality. </p>

<p>We want to be known as the reliable, innovative and user friendly software service provider in IT industry.</p>
</div>
</div>
</article>
</div>
              
           
          </div>
        </div>
      </section>
      <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">

     <section id="overview" className="pb0-overview"/>

  <article className="container">

    <div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <h2 className="title-sml">Mission</h2>

      </div>

    </div>

  </article>
  </div>


  <section className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
        <div className="cmp-text">
          <div className="row">
           
          <div id="main-text-body"><article className="container"><div className="row"><div className="col-md-10 col-sm-12 col-xs-12 mb-sm-20 wow fadeInUp animated" data-wow-delay="0.3s" >
             <p>Our mission is to provide our customers with the right expertise and solutions and to enable our partners to grow and expand their business in the region.</p>
         
</div>
</div>
</article>
</div>
              
           
          </div>
        </div>
      </section>
      {/* <div className="row">
  <div className="col-md-13 col-sm-12 col-xs-12 text-center wow fadeInDown" data-wow-delay="0.3s">    
  <h1 className="M heading text-center">OUR TEAM</h1>
        
  </div>
</div> */}
{/* <div className="leadershiptabs parbase aem-GridColumn aem-GridColumn--default--12">
  <section id="main-text-body">
          <article className="container">
      <div className="col-md-10 col-sm-12 col-xs-12 wow fadeInUp">
            <div className="row">
              <section id="leadership">
                <article className="container">
           <div className="row">
            <div className="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 leadership-link">
              <ul className="leader_tabs">                              
                <li className="leader_tabs " title="Board of Directors" data-wow-delay="1.9s">
                  <a href="about/management-profiles.html">Board of Directors</a>
                </li>                          
                <li className="leader_tabs " title="Executive Officers">
                  <a href="about/management-profiles/executive-officers.html">Executive Officers</a>
                </li>                         
                <li className="leader_tabs active" title="Founders">
                  <a href="about/management-profiles/founders.html">Founders</a>
                </li>
              </ul>	  
            </div>
          </div>
               </article>
              </section>
        </div>
            </div>
         </article>
      </section>
    
    </div> */}
    </div>
  {/* <div class="d-flex justify-content-center " id='lrow'>
    <div className="lb row col-md-7 text-center border">
   
    <div class="ccol" id="ab" onClick={() => window.scrollTo(0, 0)}>
      <Link to="/anout-us/founder">
      <div class="p-3">FOUNDER</div>
      </Link>
    </div>
    </div>
   
    
  </div> */}

  
</div>

  )
}
