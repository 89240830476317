import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';


function Blockchain() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Customized Cloud Strategies',
      text: 'Tailor-made cloud solutions that align with your specific business needs and goals. ',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Expertise in Cloud Technology',
      text: 'A team of experts adept in the latest cloud technologies and best practices. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Proactive Management and Support',
      text: 'Ongoing management and proactive support to ensure your cloud environment runs smoothly.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Security and Compliance Focus',
      text: 'A strong emphasis on maintaining a secure and compliant cloud environment. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Performance Optimization',
      text: 'Continuous monitoring and optimization to ensure peak performance of your cloud resources."',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];
 
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Blockchain</p>
      At Digitec Hub, we specialize in blockchain solutions for businesses. From development to maintenance, we prioritize security, transparency, and efficiency.
         
          <hr/>
           <br/> <strong>Decentralization</strong> 
 
           Blockchain enables decentralization by distributing data across a network of computers (nodes) rather than storing it in a central location. This decentralization eliminates the need for intermediaries, such as banks or financial institutions, thereby reducing the risk of manipulation, fraud, and censorship.
         <br/><br/>
        <strong> Transparency and Immutability</strong>
           Transactions recorded on a blockchain are transparent and immutable, meaning they cannot be altered or deleted once added to the chain. This transparency and immutability foster trust among participants, as every transaction can be traced back to its origin, promoting accountability and reducing the risk of fraud.
 
          <br/><br/><strong>Security</strong>  Blockchain employs cryptographic techniques to secure transactions and ensure the integrity of the data stored on the network. Each block in the chain is linked to the previous one through cryptographic hashes, making it extremely difficult for malicious actors to tamper with the data without being detected.
 
 
          <br/><br/><strong>Trustless Transactions</strong>A large library of apps to enhance functionality, from note-taking to music streaming. The SmartCloudApp Store offers various apps to extend the platform's capabilities.
  
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
 );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
            <div className="carousel-caption d-md-block">
  <div className="component-1">Blockchain</div>
  <div className="component-paras" style={{ color: "white", marginLeft: '20px', fontStyle: 'italic' }}>
    "Building Trust with Every Block – Pioneering Blockchain Solutions"
   
  </div>
</div>

            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Blockchain?</div> */}
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                fontSize:"25px",
                  fontFamily: "system-ui",
                }}
              >
           Blockchain-Based Application Development, Support, and Maintenance refers to the creation and management of applications built on blockchain technology. This encompasses developing decentralized applications (dApps), smart contracts, and ledger systems, along with providing ongoing support and maintenance. Our blockchain services are tailored to ensure robust, secure, and scalable solutions for various business needs. 
              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/blockchain.gif"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong style={{
                 paddingLeft:'15px'
                }}> Cloud Migration and Implementation
    </strong>
                <p class="aligned-paragraph item3text">
                Expert planning and execution of cloud migration, ensuring minimal disruption to your operations.
                </p>
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                 paddingLeft:'15px',fontSize:'18px'
                }} >
              Cloud Optimization
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Continuous optimization of cloud resources for cost efficiency and enhanced performance. 
              </p>
            </div>
    
            <div class="content-np item33">
              <strong class=""style={{
                 paddingLeft:'15px'
                }} > Managed Cloud Infrastructure
    </strong>
              <p class="aligned-paragraph item3text">
              Ongoing management of your cloud infrastructure, including monitoring, performance tuning, and security management. 
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong style={{
                 paddingLeft:'15px'
                }} >Application Management in the Cloud
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Managing and maintaining cloud-based applications for optimal functionality and user experience.
              </p>
            </div>
            <div class="content-np item35">
              <strong style={{
                paddingLeft:'15px'
                }}> Disaster Recovery and Backup
     </strong>
              <p class="aligned-paragraph item3text">
              Implementing robust disaster recovery and backup strategies for business continuity.

              </p>
            </div>
    
            <div class="content-np item36">
              <strong style={{
                 paddingLeft:'15px'
                }}>Compliance and Security
    </strong>
              <p class="aligned-paragraph item3text">
              Ensuring your cloud environment complies with relevant regulations and is fortified with advanced security measures.
              </p>
            </div>
    
            <div class="content-np item37">
              <strong style={{
                 paddingLeft:'15px'
                }}>Custom Cloud Solutions
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              Tailored cloud solutions designed to meet the unique needs of your organization. 

              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">


        
{/* <div className='sliders'> */}
  {/* Previous and Next buttons */}
  {/* <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
  {/* Modal */}
  {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
  {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
            {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
          {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}

<div className="main-cont">
        <div className="Cardcomp">
          <div className="card-container">
            <div className="row-ca">
              <div className="col-ca">
                <div className="card-head text-center">
                  <h2 className="head-t ">USP of Blockchain Services</h2>
                  {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-container'>
        <div className='row-ck'>
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1" >
                    <h2>Expertise in Blockchain Technology <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/blockchain.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                    <h2>Expertise in Blockchain Technology</h2>
                    <p>Our team possesses deep expertise in the latest blockchain technologies and trends.  </p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Customized Solutions <FontAwesomeIcon icon={faArrowRight} /> </h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/hand.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
                  <h2>Customized Solutions</h2>
                    <p>Developing solutions tailored specifically to your business requirements.  </p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Robust Security Protocols <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/shield.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
                  <h2>Robust Security Protocols</h2>
                    <p>Prioritizing security in every blockchain solution we develop.  </p>
                    
            </div>
        </div>
    </div>
    </div>
    
    
        
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>Scalable and Future-Proof Solutions <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/presentation.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>Scalable and Future-Proof Solutions</h2>
                    <p>Ensuring that our blockchain applications are scalable and adaptable to future technological advancements.</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>End-to-End Service Delivery <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/route.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>End-to-End Service Delivery</h2>
                    <p>From ideation to deployment and beyond, we offer complete blockchain service packages. </p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Interoperability <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/interact.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>Interoperability </h2>
                    <p> By facilitating interoperability, we help you unlock new opportunities for collaboration, streamline processes, and enhance the overall efficiency of your business operations.</p>
                    
            </div>
        </div>
    </div>
    </div>
  
    
        
            {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
    {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
    </div>
    </div>
    </div>
 
     
          {/* <CarouselSlider/> */}
         
        </div>
    
        <div className="section5" >
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Thorough Assessment"
        description="Evaluating your current IT infrastructure and determining the optimal cloud strategy. "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Strategic Migration and Implementation"
        description="Carefully planned and executed migration to the cloud, coupled with end-to-end implementation tailored to your needs. "
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="Regular Monitoring and Management"
        description="Continuous monitoring and management of your cloud infrastructure to ensure optimal performance. "
        linkUrl=""
      />
     
      <CaseStudyCard
        imageUrl="/Images/casestudyimg4.jpg"
        title="Security and Compliance Checks"
        description="Regular security assessments and compliance checks to protect your data and applications."
        linkUrl=""
      />
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div> 
    
        
      </div>
      
    </div>
  )
}

export default Blockchain
