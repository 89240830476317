import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

function GenAI() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Innovative Applications",
      text: " We are at the forefront of applying Generative AI in innovative ways across various sectors.  ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customization and Flexibility",
      text: " Our solutions are highly customizable to meet the unique needs of each industry. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Expert Team",
      text: " Our team comprises AI specialists who are skilled in the latest advancements in Generative AI technology.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Ethical AI Practices",
      text: " We commit to the ethical and responsible use of AI in all our solutions.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Integrated Solutions",
      text: "Our Generative AI services are designed to integrate seamlessly with existing business systems and processes. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Generative AI Services </p>
      At OMSK CO,. LTD, we harness the innovative potential of Generative AI to
      revolutionize various sectors, including our SmartVision platform and
      e-commerce support services. Our solutions are designed to bring
      creativity, efficiency, and advanced problem-solving capabilities into
      your business operations.
      <hr />
      <br /> <strong>Cross-Domain Applications</strong>
      GenAI might aim to create AI technologies that can seamlessly integrate
      and adapt to various domains, such as healthcare, finance, education,
      transportation, and entertainment, unlocking new possibilities for
      innovation and societal impact.
      <br />
      <br />
      <strong> Collaborative Intelligence</strong>
      GenAI could foster the collaboration between humans and AI systems,
      leveraging the strengths of both to solve complex problems, enhance
      creativity, and drive scientific and technological advancements
      <br />
      <br />
      <strong>Sustainability and Global Challenges</strong> The purpose of GenAI
      might include addressing global challenges such as climate change,
      healthcare disparities, poverty, and cybersecurity by leveraging AI
      technologies to develop innovative solutions and inform evidence-based
      decision-making.
      <br />
      <br />
      <strong>Ethical and Responsible AI</strong>
      GenAI could prioritize the development of AI systems that adhere to
      ethical principles, promote fairness, transparency, and accountability,
      and mitigate potential risks and biases associated with AI technologies.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/firstcomponant.jpg"
                className="d-block w-100"
                style={{ height: "700px" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1">Generative AI Services </div>
                <div
                  className="component-paras"
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontStyle: "italic",
                  }}
                >
                  "Unleashing AI’s Creative Power – Transforming Industries with
                  Generative AI"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Generative AI Services </div> */}
          </div>

          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "25px",
                  fontFamily: "system-ui",
                }}
              >
                Generative AI services at OMSK CO,. LTD involve leveraging
                artificial intelligence to generate new and unique data, ideas,
                solutions, and content. Our services extend across various
                domains, from enhancing visual surveillance capabilities with
                SmartVision to powering e-commerce industries with AI-driven
                chatbots. We aim to facilitate the adoption of AI in a way that
                is both transformative and seamlessly integrated with your
                business processes.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"
                  src="/Images/Genai.jpg"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>

          <div class="section3parts">
            <div class="content-np item31">
              <div>
                <strong
                  style={{ paddingLeft: '20px' }}
                >
                  {" "}
                  SmartVision AI Integration
                </strong>
                <p class="aligned-paragraph item3text">
                  Implementing Generative AI in our SmartVision platform for
                  advanced surveillance and data analysis capabilities.
                </p>
              </div>
            </div>

            <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft:'15px',color:'black',fontSize:'18px'
                }}
              >
                Content Generation
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}> 
                Utilizing AI to create unique, high-quality content for various
                digital platforms.
              </p>
            </div>

            <div class="content-np item33">
              <strong
                class=""
                style={{
                  paddingLeft:'15px'
                }}
              >
                {" "}
                AI-Enabled Chatbots for E-Commerce
              </strong>
              <p class="aligned-paragraph item3text">
                Developing sophisticated chatbots that can handle customer
                queries, improve service efficiency, and personalize user
                experiences in e-commerce.
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>

            <div class="content-np item34">
              <strong
                style={{
                  paddingLeft:'15px'
                 }}
              >
                Innovative Applications
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
                Tailoring Generative AI solutions to fit specific industry
                requirements and challenges.
              </p>
            </div>
            <div class="content-np item35">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                {" "}
                Implementation and Deployment
              </strong>
              <p class="aligned-paragraph item3text">
                Seamlessly implementing and deploying these solutions on live
                platforms, including integration with SmartVision.
              </p>
            </div>

            <div class="content-np item36">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                Custom Solutions on SmartVision Platform
              </strong>
              <p class="aligned-paragraph item3text">
                Tailoring OCR and object recognition solutions to work
                effectively with the SmartVision Platform, enhancing overall
                performance and capabilities.
              </p>
            </div>

            <div class="content-np item37">
              <strong
                style={{
                  paddingLeft:'15px'
               }}
              >
                Continuous Innovation
              </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              User is interested in continuous innovation and research in AI technologies to stay at the forefront of industry trends and advancements.
              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        <div class="section4">
          <div className="sliders">
            {/* Previous and Next buttons */}
            <button
              className="nav-button-slider prev"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              &lt;
            </button>
            <button
              className="nav-button-slider next"
              onClick={() => sliderRef.current?.slickNext()}
            >
              &gt;
            </button>
            {/* Modal */}
            {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}

            <div className="slidercontainer">
              <Slider ref={sliderRef} {...settings}>
                {slideData.map((slide) => (
                  <div key={slide.id} className="slider-item">
                    <div className="slidecontents">
                      <h2 className="slideheading h2-np slidecontent">
                        {slide.heading}
                      </h2>
                      <p className="slidetext p-np slidecontent">
                        {slide.text}
                      </p>
                      {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="Needs Assessment and Consultation"
              description=" We start by understanding your business needs and the potential applications of Generative AI. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Strategic Development and Implementation"
              description=" Developing a strategic plan for the implementation of Generative AI solutions and executing it in alignment with your business objectives. "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Continuous Monitoring and Optimization"
              description=" Regularly monitoring the AI solutions and optimizing them for improved performance. "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Training and Support"
              description="Providing training and support to ensure your team can effectively utilize the Generative AI solutions. "
              linkUrl=""
            />

            {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenAI;
