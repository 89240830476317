import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import SideHoverModal from "./SideHoverModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Dropdownmenu5 from "./Dropdownmenu5";
import DropdownMenu from "./DropdownMenu";
import DropdownMenu1 from "./DropdownMenu1";
import DropdounMenu2 from "./DropdounMenu2";
import "./DropdownMenu.css"; // Import the CSS file for styling

import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import DropdownMenu3 from "./DropdownMenu3";
// import DropdownMenu4 from "./DropdownMenu4";

export default function NavBar() {
  const [activeSection, setActiveSection] = useState("default-section");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [sideHoverModal, setSideHoverModal] = useState(false);
  const [modalPage, setModalPage] = useState("business");

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    scrollTop > 0 ? setIsScrolled(true) : setIsScrolled(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      const sidebar = document.getElementById("sidebar");
      const sideModal = document.getElementById("hover-modal");
      if (sideModal !== null) {
        sideModal.style.width = "100%";
      }
      sidebar.classList.add("close");
    }
  }, [sideHoverModal]);
  function scrollToSection(sectionId) {
    // Implementation of scrollToSection function, e.g., scroll to the specified section
  }

  function showDropdown() {
    const dropdown = document.getElementById("dropdownMenu");
    dropdown.classList.add("active");
  }

  function hideDropdown() {
    const dropdown = document.getElementById("dropdownMenu");
    dropdown.classList.remove("active");
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 50; // Adjust as needed
      setIsScrolled(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleButtonClick = () => {
    setSideHoverModal(false);
    setSidebarOpen(!sidebarOpen);
  };

  document.addEventListener("DOMContentLoaded", function () {
    var line = document.getElementById("scrollLine");

    window.addEventListener("scroll", function () {
      // Add the 'scrolled' class to the element when scrolling
      if (window.scrollY > 0) {
        line.classList.add("scrolled");
      } else {
        line.classList.remove("scrolled");
      }
    });
  });

  return (
    <div
      id="navbar-container"
      className={`${
        isScrolled ? "nav-background" : ""
      } container-fluid pt-3 pb-3 d-flex flex-row `}
    >
      <nav className="navbar navbar-expand-lg col-md-11 col-sm-11">
        <Link to="/">
          <img
            src={isScrolled ? "/Images/OMSK-logo-black.png" : "/Images/white.png"}
            id="myImage"
            width="200"
            height="120"
            onClick={() => window.scrollTo(0, 0)}
          />
        </Link>

        <div
          className={`nav-data `}
          data-aos="fade-out"
          id="navbarSupportedContent"
        >
          <ul
            className={`navbar-nav ms-auto mb-2 mb-lg-0 `}
          >
            {/* <li className="nav-item">
              <Link to="" className="menu-link ">
              <DropdownMenu />
              </Link>
            </li> */}

           

            {/* <li className="nav-item">
              <Link to="" className="menu-link ">
                
                <DropdounMenu2 />
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link to="" className="menu-link " style={{ color: "white" }}>
                <DropdownMenu3 />
              </Link>
            </li> */}

            {/* <li className="nav-item">
              <Link to="" className="menu-link " style={{ color: "white" }}>
                Case studies
              </Link>
            </li> */}

            {/* <li className="nav-item">
              <Link
                to="/Contact"
                className="menu-link "
                style={{ color: "white" }}
              >
                Contact Us
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
      {/* <div className="social-media-bar">
        <a
          href="https://www.youtube.com/@ExpertITDataInformaticsLLP"
          className="youtube social "
        >
          <FontAwesomeIcon icon={faYoutube} size="0x" />
        </a>

        <a
          href="https://www.instagram.com/expert._it/"
          className="instagram social"
        >
          <FontAwesomeIcon icon={faInstagram} size="1x" />
        </a>
        <a href="https://www.twitter.com" className="twitter social">
          <FontAwesomeIcon icon={faTwitter} size="1x" />
        </a>
        <a
          href="https://www.facebook.com/fb.expertit"
          className="facebook social "
        >
          <FontAwesomeIcon icon={faFacebook} size="1x" />
        </a>

        <a
          href="https://in.linkedin.com/company/expertit-data-informatics-llp"
          className="Linkedin social "
        >
          <FontAwesomeIcon icon={faLinkedin} size="1x" />
        </a>
        <a href="https://www.google.com" className="facebook social ">
          <FontAwesomeIcon icon={faGoogle} size="1x" />
        </a>

       
      </div> */}

      <div
        className={`${
          isScrolled && !sidebarOpen ? "toogle-btn-bg" : ""
        } toogle-btn start-100 `}
      >
        <div
          onClick={() => {
            setSideHoverModal(false);
            setSidebarOpen(!sidebarOpen);
          }}
          className={`hamburger ${sidebarOpen ? "open" : ""}`}
        >
          <div
            className={`${
              isScrolled && !sidebarOpen ? "var-line-scroll" : ""
            } top ver-line`}
          ></div>
          <div
            className={`${
              isScrolled && !sidebarOpen ? "var-line-scroll" : ""
            } middle ver-line`}
          ></div>
          <div
            className={`${
              isScrolled && !sidebarOpen ? "var-line-scroll" : ""
            } bottom ver-line`}
          ></div>
        </div>
      </div>

      <div
        className={`${!sidebarOpen ? "close" : ""} sidebar pt-5`}
        id="sidebar"
      >
        <div
          className="mt-5"
          onClick={() => {
            setSidebarOpen(false);
          }}
        >
          <Link to="/" className="menu-link w-100">
            <img
              src="/Images/OMSK-logo-black.png"
              alt=""
              id="myImage-sidebar"
              style={{ maxWidth: "60%",height: "60%", marginLeft: "-10px" }}
            />
          </Link>
        </div>

        <div
          className="mt-2"
          onClick={() => {
            setSideHoverModal(false);
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/" className="menu-link ">
            Home
          </Link>
        </div>
        <div
          className="mt-2 "
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/about-us" className="menu-link ">
            About us
          </Link>
        </div>
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={async () => {
            setSideHoverModal(false);
           
          }}
        >
          <Link to="/businesses" className="menu-link ">
            Businesses
          </Link>
        </div>

        {/* <div
          onMouseEnter={() => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("bussiness");
          }}
          className="mt-2"
        >
          <a className="menu-link ">Businesses</a>
        </div> */}
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          {/* <Link to="/why" className="menu-link ">
            Why
          </Link> */}
        </div>

        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          {/* <Link to="/what" className="menu-link ">
            What
          </Link> */}
        </div>
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          {/* <Link to="/How" className="menu-link ">
            How
          </Link> */}
        </div>
        <div
          className="mt-2"
          onMouseEnter={() => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("product");
          }}
        >
          <a className="menu-link ">Products</a>
        </div>
        {/* <div
          className="mt-2"
          onMouseEnter={async () => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("services");
          }}
        >
          <a className="menu-link ">Services</a>
        </div> */}
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={async () => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("services");
          }}
        >
          <Link to="/services" className="menu-link ">
            Services
          </Link>
        </div>

        {/* <div className="mt-2" onClick={() => setSideHoverModal(false)}>
          <Link to="/bharatmake" className="menu-link ">
            BharatMake
          </Link>
        </div> */}
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/Clientlist" className="menu-link ">
            Client list
          </Link>
        </div>
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/Contact" className="menu-link ">
            Contact Us
          </Link>
        </div>

        {/* <div className="sidebar-nav">
          <div className="mt-4">
            <Link to="/careers" className="menu-link ">
              Careers
            </Link>
          </div>
          <div className="mt-4">
            <Link to="/investors" className="menu-link ">
              Investors
            </Link>
          </div>
          <div className="mt-4">
            <Link to="/navigate-your-next" className="menu-link ">
              Navigate your Text
            </Link>
          </div>
        </div> */}
        {/* <div className="">
          <h6 className="text-black" style={{ paddingTop: "20px" }}>
            Follow US
          </h6>
          <div className="d-flex flex-row mt-2" style={{ paddingTop: "20px" }}>
            <a
              href="https://www.youtube.com/@ExpertITDataInformaticsLLP"
              className="youtube social "
            >
              <FontAwesomeIcon icon={faYoutube} size="1x" />
            </a>

            <a
              href="https://www.facebook.com/fb.expertit"
              className="facebook social  "
            >
              <FontAwesomeIcon icon={faFacebook} size="1x" />
            </a>
            <a href="https://www.twitter.com" className="twitter social">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>

            <a
              href="https://www.instagram.com/expert._it/"
              className="instagram social "
            >
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>

            <a
              href="https://in.linkedin.com/company/expertit-data-informatics-llp"
              className="Linkedin social "
            >
              <FontAwesomeIcon icon={faLinkedin} size="1x" />
            </a>
          </div>
        </div> */}
      </div>

      {sideHoverModal && (
        <SideHoverModal
          handleClick={() => {
            setSideHoverModal(!sideHoverModal);
            setSidebarOpen(false);
          }}
          page={modalPage}
        />
      )}
    </div>
  );
}
