import React, { useState } from 'react';
import './Contact-Us.css'; // Import the CSS file for Contact component
import './Contact-Us-mobile.css'; // Mobile styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarker } from '@fortawesome/free-solid-svg-icons';



function Contact() {
  // Create an array to store progress values for each item
  const [progressValues, setProgressValues] = useState([
    50, // Request for Services
    75, // Technology and Market Analyst Queries
    30, // Finacle-related Quries
    90, // BPM-related Quries
    40, // Finacle Analyst and Investor Queries
    60, // Media Queries
    80, // Career-related Queries
    20, // Website Feedback
    10, // Supplier Payment-related Queries
  ]);

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  //  const [company, setCompany] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      phoneNumber,
      email,
    //  company,
      address,
    };

    try {
      // Simulate a successful response
      await mockSubmit(formData);
      console.log('Data saved successfully');
      alert('Data successfully submitted!');
      setName('');
      setPhoneNumber('');
      setEmail('');
      // setCompany('');
      setAddress('');
    } catch (error) {
      console.error('Error saving data:', error);
      alert('Error submitting data. Please try again.');
    }
  };

  // Simulate submitting data
  const mockSubmit = async (data) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
        // Uncomment the following line to simulate an error
        // reject(new Error('Mock API Error'));
      }, 1000);
    });
  };
  const [showLocation1, setShowLocation1] = useState(true);

  const toggleLocation = () => {
    setShowLocation1((prev) => !prev);
  };

  return (
    <div className='cont'>
    <div className={`popup ${showPopup ? 'show' : ''}`}>
        
      </div>
      <div className="contact-container">
        <h1>How Can I Help You?</h1>
      </div>
      {/* <div className="additional-content">
         <h1 className="looking-for">I'm Looking For</h1>

        <div className="grid-container">
          <div className="grid-item">
            <a href="contact/item1.html">
              <h2>Request for Services</h2>
            </a>
            <progress value={progressValues[0]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item2.html">
              <h2>Technology and Market Analyst Queries</h2>
            </a>
            <progress value={progressValues[1]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item3.html">
              <h2>Finacle-related Quries</h2>
            </a>
            <progress value={progressValues[2]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item4.html">
              <h2>BPM-related Queries</h2>
            </a>
            <progress value={progressValues[3]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item5.html">
              <h2>Finacle Analysts And Investor Quries</h2>
            </a>
            <progress value={progressValues[4]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item6.html">
              <h2>Media Quries</h2>
            </a>
            <progress value={progressValues[5]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item7.html">
              <h2>Website Feedback</h2>
            </a>
            <progress value={progressValues[6]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item8.html">
              <h2>Career-related Quries</h2>
            </a>
            <progress value={progressValues[7]} max="100"></progress>
          </div>
          <div className="grid-item">
            <a href="contact/item9.html">
              <h2>Supplier-related Payment Queries</h2>
            </a>
            <progress value={progressValues[8]} max="100"></progress>
          </div>
         </div>           
       </div> */}


           {/* <div className='Contact_page'>
          <h1>ContactUs</h1>
          <p>Contact us about anything related to our company or services.
            We'll do our best to get back to you as soon as possible.</p>
           
 <div className="form-container">
  <div className="form-group">
    <label htmlFor="name">Name:</label>
    <input
      type="text"
      id="name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="phoneNumber">Phone Number:</label>
    <input
      type="tel"
      id="phoneNumber"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
      required
    />
  </div>
  <div className="form-group">
    <label htmlFor="Email">Email:</label>
    <input
      type="text"
      id="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
    />
  </div>
 
  <div className="form-group">
    <label htmlFor="Address">Address:</label>
    <input
      type="text"
      id="Address"
      value={address}
      onChange={(e) => setAddress(e.target.value)}
      required
    />
  </div>
  
</div>
<button type="submit" className="submit-button">Submit</button>  
        </div> */}


        <div className='Locations'>
      <div className='Location-name'>
         Office-Address
      </div>
<div className="contact-section">
<div className="contact-column">
  <h3 className='h30'>Thailand</h3>
  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faMapMarker} className="Product-icon" />
    </div>
    <div>
      <p>Company name: omsk Company Limited.
Address: Arest No. 588/213 Village No. 2, Phraeksa Mai Subdistrict Mueang Samut Prakan, District Samut Prakan Province. 10280 Thailand
</p>
    </div>
  </div>

  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faPhone} className="Product-icon" />
    </div>
    <div>
      <p>+66 0989276428</p>
    </div>
  </div>

  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faEnvelope} className="Product-icon" />
    </div>
    <div>
      <p>omskorawan@gmail.com
</p>
    </div>
  </div>
</div>

 <div className="contact-column">
  <h3 className='h31'>Pune</h3>
  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faMapMarker} className="Product-icon" />
    </div>
    <div>
      <p>No. 1, Expert IT Technology Headquarters, SrNo- 97/7b, Madhavbaugh Villoo Poonawala Road, near Serum India Terminal, Pune, Maharashtra 412307</p>
    </div>
  </div>

  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faPhone} className="Product-icon" />
    </div>
    <div>
      <p>+91 8600187755</p>
    </div>
  </div>

  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faEnvelope} className="Product-icon" />
    </div>
    <div>
      <p>support@expertit.in</p>
    </div>
  </div>
</div>

<div className="contact-column">
  <h3 className='h32'>UAE</h3>
  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faMapMarker} className="Product-icon" />
    </div>
    <div>
      <p>302, Pluto Building, Dubailand, PO BOX 392380, Dubai, UAE</p>
    </div>
  </div>

  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faPhone} className="Product-icon" />
    </div>
    <div>
      <p>+971 42 342931</p>
    </div>
  </div>

  <div className="contact-info">
    <div className="contact-icon">
      <FontAwesomeIcon icon={faEnvelope} className="Product-icon" />
    </div>
    <div>
      <p>altaf@expertit.in</p>
    </div>
  </div>
</div> 

  </div>

  
</div>   
    </div>
  );
}

export default Contact